import React from 'react'
import { Row, Col } from 'react-bootstrap'

class NavbarBack extends React.Component {
    handleGoBack = () => {
        window.history.back()
    }

    render() {
        return (
            <div style={{ backgroundColor: 'black' }}>
                <Row>
                    <Col className='d-flex justify-content-between' sm={12}>
                        <h6 onClick={this.handleGoBack} style={{ color: 'white', fontFamily: 'microextend', margin: '20px', cursor: 'pointer' }}>
                            <i className="fas fa-chevron-left"></i> Back
                        </h6>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default NavbarBack