import React, { Component } from 'react'
import '../../pages/App/App.css'
import { NavLink, useLocation, withRouter } from 'react-router-dom'
import {
    HOMEMOBXL,
    EVENTMOBXL,
    VIDEOMOBXL,
    LIBRARYMOBXL,
    ARTICLEMOBXL,
    ICLIBON,
    ICEVENTON,
    ICHOMEON,
    ICARTICLEON,
    ICVIDEOON
}
    from '../../../assets/icon/buttom-bar-mobile';


// REACT HOOKS
// const FooterMobile = () =>{
//     const location = useLocation();
//     const {pathname} = location;

//     return(
//         <div className="bg-dark fixed-bottom" style={{ width: '100%', height: '4rem', padding: '.75rem 1.5rem' }}>
// <ul className="list-unstyled d-flex justify-content-between">
//     <li>
//         <NavLink to="/" className="d-flex flex-column align-items-center tab-link">
//             <img className="mb-1" src={pathname === '/' ?  ICHOMEON.default : HOMEMOBXL.default} width={24}></img>
//             <span className='footer-style' style={{ color: `${pathname === '/' ? '#FAA21B' : '#FFFFFF'}` }}>Home</span>
//         </NavLink>
//     </li>
//     <li>
//         <NavLink to="/article" className="d-flex flex-column align-items-center">
//             <img className="mb-1" src={pathname === '/article' ? ICARTICLEON.default : ARTICLEMOBXL.default} width={24}></img>
//             <span className='footer-style' style={{ color: `${pathname === '/article' ? '#FAA21B' : '#FFFFFF'}` }}>Article</span>
//         </NavLink>
//     </li>
//     <li><NavLink to="/live" className="d-flex flex-column align-items-center">
//         <img className="mb-1" src={pathname === '/live' ? ICEVENTON.default : EVENTMOBXL.default} width={24}></img>
//         <span className='footer-style' style={{ color: `${pathname === '/live' ? '#FAA21B' : '#FFFFFF'}` }}>Calendar</span>
//     </NavLink></li>
//     <li><NavLink to="/library" className="d-flex flex-column align-items-center">
//         <img className="mb-1" src={pathname === '/library' ? ICLIBON.default : LIBRARYMOBXL.default} width={24}></img>
//         <span className='footer-style' style={{ color: `${pathname === '/library' ? '#FAA21B' : '#FFFFFF'}` }}>Library</span>
//     </NavLink></li>
//     <li><NavLink to="/video" className="d-flex flex-column align-items-center">
//         <img className="mb-1" src={pathname === '/video' ? ICVIDEOON.default : VIDEOMOBXL.default} width={24}></img>
//         <span className='footer-style' style={{ color: `${pathname === '/video' ? '#FAA21B' : '#FFFFFF'}` }}>Video</span>
//     </NavLink></li>
// </ul>
//             </div>
//     )
// }

// export default FooterMobile;

class FooterMobile extends Component {

    render() {
        const { location } = this.props;
        const { pathname } = location;
        return (
            <div className="bg-dark fixed-bottom" style={{ width: '100%', height: '4rem', padding: '.75rem 1.5rem' }}>
                <ul className="list-unstyled d-flex justify-content-between">
                    <li>
                        <NavLink to="/home" className="d-flex flex-column align-items-center tab-link">
                            <img className="mb-1" src={pathname === '/home' ? ICHOMEON.default : HOMEMOBXL.default} width={24}></img>
                            <span className='footer-style' style={{ color: `${pathname === '/home' ? '#FAA21B' : '#FFFFFF'}` }}>Home</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/article" className="d-flex flex-column align-items-center">
                            <img className="mb-1" src={pathname === '/article' ? ICARTICLEON.default : ARTICLEMOBXL.default} width={24}></img>
                            <span className='footer-style' style={{ color: `${pathname === '/article' ? '#FAA21B' : '#FFFFFF'}` }}>Article</span>
                        </NavLink>
                    </li>
                    <li><NavLink to="/live" className="d-flex flex-column align-items-center">
                        <img className="mb-1" src={pathname === '/live' ? ICEVENTON.default : EVENTMOBXL.default} width={24}></img>
                        <span className='footer-style' style={{ color: `${pathname === '/live' ? '#FAA21B' : '#FFFFFF'}` }}>Calendar</span>
                    </NavLink></li>
                    <li><NavLink to="/library" className="d-flex flex-column align-items-center">
                        <img className="mb-1" src={pathname === '/library' ? ICLIBON.default : LIBRARYMOBXL.default} width={24}></img>
                        <span className='footer-style' style={{ color: `${pathname === '/library' ? '#FAA21B' : '#FFFFFF'}` }}>Library</span>
                    </NavLink></li>
                    <li><NavLink to="/video" className="d-flex flex-column align-items-center">
                        <img className="mb-1" src={pathname === '/video' ? ICVIDEOON.default : VIDEOMOBXL.default} width={24}></img>
                        <span className='footer-style' style={{ color: `${pathname === '/video' ? '#FAA21B' : '#FFFFFF'}` }}>Video</span>
                    </NavLink></li>
                </ul>
            </div>
        )
    }
}

export default withRouter(FooterMobile);