import React from "react";
import axios from "axios";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarBack from "../../organism/NavbarBack";
import { ICPREMIUM } from "../../../assets/icon/buttom-bar-mobile/index";
import { API_URL } from "../../../config/common/baseurl";
import { Form, Button, Row, Col, Modal, CloseButton } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Cookise from "cookie-universal";

const cookie = new Cookise();

class PaymentPaypal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toDetailPayment: false,
      bankName: "",
      selected: "",
      toPurchaseHistory: false,
      toHome: false,
      selectedOption: "",
      showDetailPayment: false,
      dataPrice: "",
      dataAccNumber: "",
    };
  }

  handleCloseModal = () => {
    // console.log('close sudah d klik')
    this.setState({ showDetailPayment: false });
  };

  handleCopyVA = () => {
    navigator.clipboard.writeText(this.state.dataAccNumber);
    alert("copied");
  };

  handleChange = (e) => {
    const value = e.target.getAttribute("value");
    // console.log('val', value)
    this.setState({
      bankName: value,
    });
  };

  handleOptionChange = (e) => {
    this.setState({ selectedOption: e.target.value });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    // console.log('You have selected:', this.state.selectedOption)
  };

  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }

    // console.log('cek', this.state.bankName)
    const { toPurchaseHistory, toHome, showDetailPayment } = this.state;
    if (toPurchaseHistory) return <Redirect to="/purchasehistory" />;
    if (toHome) return <Redirect to="/home" />;
    return (
      <div className="big-container" style={{ backgroundColor: "black" }}>
        <NavbarBack />
        <div className="container-fluid">
          <div className="row justify-content-md-center ">
            <div className="col-md-4 d-flex">
              <div className="pr-2">
                <img fluid src={ICPREMIUM.default} alt="icon-premium" />
              </div>
              <div className=" pt-2">
                <h5 className="title-livedetail">{cookie.get("title")}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <p className="date-livedetail text-muted">
                {cookie.get("time")} - {cookie.get("date")}
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail pt-3">Details :</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Ticket ID</p>
                <p className="price-livedetail">{cookie.get("trans_id")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Content</p>
                <p className="price-livedetail">{cookie.get("title")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Category</p>
                <p className="price-livedetail">{cookie.get("category")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Email</p>
                <p className="price-livedetail">{cookie.get("email")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Total</p>
                <p className="price-livedetail">{cookie.get("price")}</p>
              </div>
              <div class="row-md-4 d-flex justify-content-between mb-4">
                <div class="col text-white">2. Choose Your Account Bank</div>
              </div>
              <Form.Group as={Row} className="mb-3 text-white">
                <Col sm={10}>
                  <Form.Check
                    type="radio"
                    label="SGD"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                    ref="SGD"
                    value="SGD"
                    // checked={this.state.selectedOption === 'BCA'}
                    onChange={this.handleChange}
                  />
                  <Form.Check
                    type="radio"
                    label="THB"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios2"
                    ref="THB"
                    value="THB"
                    // checked={this.state.selectedOption === 'BNI'}
                    onChange={this.handleChange}
                  />
                  <Form.Check
                    type="radio"
                    label="USD"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios3"
                    ref="USD"
                    value="USD"
                    // checked={this.state.selectedOption === 'BRI'}
                    onChange={this.handleChange}
                  />

                  <Button
                    // type='submit'
                    onClick={this.handlePaypal}
                    // onClick={this.handleRegister}
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#faa21b",
                      border: "none",
                      fontFamily: "microextend",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  >
                    Submit
                  </Button>
                </Col>
              </Form.Group>
            </div>
          </div>
        </div>

        <Footer />
        <FooterMobile />
        <Modal
          show={showDetailPayment}
          onHide={() => this.setState({ popUpShare: true })}
        >
          <Modal.Header style={{ backgroundColor: "#040124" }}>
            <h5>Detail Payment</h5>
            <p
              onClick={this.handleCloseModal}
              className="text-white pt-2"
              closeButton
            >
              X
            </p>
            {/* <CloseButton white /> */}
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#040124", minHeight: "30vh" }}>
            <div className="d-flex flex-column justify-content-around">
              <h6 className="text-white d-flex justify-content-center">
                please transfer Rp. {this.state.dataPrice} <br /> to{" "}
                {this.state.dataAccNumber}
              </h6>
              <div className="d-flex justify-content-center">
                <Button
                  // type='submit'
                  onClick={this.handleCopyVA}
                  // onClick={this.handleRegister}
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#faa21b",
                    border: "none",
                    fontFamily: "microextend",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  Copy VA
                </Button>
              </div>
            </div>
          </Modal.Body>
          <div
            className="p-1"
            style={{ backgroundColor: "#040124" }}
            onClick={() => this.setState({ toPurchaseHistory: true })}
          >
            <span
              style={{
                fontFamily: "microextend",
                cursor: "pointer",
                color: "white",
                margin: "auto",
              }}
            >
              Go To Purchase History
            </span>
            <i className="fas fa-arrow-right p-2 text-white"></i>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PaymentPaypal;
