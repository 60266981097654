import React from 'react'
import { ONE } from '../../../assets/image/podcast'

class Article extends React.Component {
    render() {
        return (
            <div className='container-fluid'>
                <div className='d-flex overflow-auto'>
                    <div className='col-md-4'>
                        <div className='card text-center bg-dark'>
                            <img src={ONE.default} className='card-img img-fluid' alt='onepict' />
                            <div className='card-body'>
                                <h5 className=' card-title text-left'>The impact of the pandemic on athletes - One on One Eps.01</h5>
                                <div className='d-flex'>
                                    <img className='rounded-circle img-fluid' src={ONE.default} style={{maxHeight:'50px', maxWidth:'50px', marginBottom:'20px'}} alt='contributor'/>
                                        <div>
                                            <h4 className='card-text text-white text-left' style={{fontSize:'12px', paddingLeft:'2px'}} >Angga Manggala</h4>
                                            <h4 className='card-text text-white text-left text-muted' style={{fontSize:'12px', paddingLeft:'2px'}} >20 januari 2020</h4>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Article
