import React from "react";
import axios from "axios";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarBack from "../../organism/NavbarBack";
import { ICPREMIUM } from "../../../assets/icon/buttom-bar-mobile/index";
import { Redirect, Link, useLocation } from "react-router-dom";
import DetailLiveStreaming from "../DetailLiveStreaming";
import { API_URL } from "../../../config/common/baseurl";
import Cookise from "cookie-universal";

const cookie = new Cookise();

const domain = "https://mpro.world/";

class Paynow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toPurchase: false,
      bankName: "",
      isClickVA: "",
      toPaymentEwallet: false,
      toHome: false,
    };
  }

  handleChange = (e) => {
    this.setState({ bankName: e.target.value });
  };

  handleVirtualAccount = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const bankName = this.state.bankName;
    const transID = cookie.get("trans_id");
    const price = cookie.get("price");
    const name = "MPRO";
    axios
      .post(
        `${API_URL}/payment/va`,
        {
          paymentMethod: "VA",
          transactionId: transID,
          bankName: bankName,
          amount: price,
          name: name,
        },
        token
      )
      .then((res) => {
        console.log("res", res.data);
        alert(
          `please transfer ${price} to ${res.data.data.data.accountNumber}`
        );
        this.setState({ toPurchase: true });
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  handleCC = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const transID = cookie.get("trans_id");
    const price = cookie.get("price");
    axios
      .post(
        `${API_URL}/payment/cc`,
        {
          paymentMethod: "CC",
          transactionId: transID,
          amount: price,
          redirectUrl: domain,
        },
        token
      )
      .then((res) => {
        // this.setState({ toPurchase: true });
        if (res.data.data.Error) {
          alert("transaction Id already used");
          this.setState({ toHome: true });
        } else {
          // console.log('res', res)
          // console.log('urlPayment', res.data.data.data.checkoutUrl)
          window.open(res.data.data.data.checkoutUrl);
          this.setState({ toPurchase: true });
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  handlePayPal = () => {
    // console.log('dollarnich', cookie.get('dollar'))
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const transID = cookie.get("trans_id");
    const price = cookie.get("dollar");
    axios
      .post(
        `${API_URL}/payment/paypal`,
        {
          paymentMethod: "PAYPAL",
          transactionId: transID,
          currency: "USD",
          amount: price,
        },
        token
      )
      .then((res) => {
        // console.log('res', res.data.data)
        console.log("urlPayment", res.data.data.data.checkoutUrlDesktop);
        // if (res.data.data.Error) {
        //     alert('transaction Id already used')
        //     this.setState({ toHome: true })
        // } else
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
          window.open(res.data.data.data.checkoutUrlMobile);
        } else {
          window.open(res.data.data.data.checkoutUrlDesktop);
        }
        this.setState({ toPurchase: true });
      })
      .catch((err) => {
        // console.log(err)
        alert("transaction Id already used");
        this.setState({ toHome: true });
      });
  };

  handleQris = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const transID = cookie.get("trans_id");
    const price = cookie.get("price");
    axios
      .post(
        `${API_URL}/payment/qris`,
        {
          paymentMethod: "QRIS",
          transactionId: transID,
          amount: price,
        },
        token
      )
      .then((res) => {
        console.log("url desktop", res.data.data.data.showQRUrl);
        window.open(res.data.data.data.showQRUrl);
        this.setState({ toPurchase: true });
      })
      .catch((err) => {
        console.log(err);
        alert("transaction Id already used");
        this.setState({ toHome: true });
      });
  };
  handleToPurchasedHistory = () => {
    this.setState({ toPurchase: true });
  };

  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    const { location } = this.props;
    const { pathname } = location;
    // console.log('bank name', this.state.bankName)
    // console.log('WAKTU', cookie.get('time'))
    // const location = useLocation()
    // console.log('locatiom', location.state.trans_Id)
    const { toDetailPayment, toPurchase, toHome } = this.state;
    if (toDetailPayment) return <Redirect to="/gopay" />;
    if (toPurchase) return <Redirect to="/purchasehistory" />;
    if (toHome) return <Redirect to="/home" />;
    return (
      <div className="big-container" style={{ backgroundColor: "black" }}>
        <NavbarBack />
        <div className="container-fluid">
          <div className="row justify-content-md-center ">
            <div className="col-md-4 d-flex">
              <div className="pr-2">
                <img fluid src={ICPREMIUM.default} alt="icon-premium" />
              </div>
              <div className=" pt-2">
                <h5 className="title-livedetail">{cookie.get("title")}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <p className="date-livedetail text-muted">
                {cookie.get("time")} - {cookie.get("date")}
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail pt-3">Details :</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Ticket ID</p>
                <p className="price-livedetail">{cookie.get("trans_id")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Content</p>
                <p className="price-livedetail">{cookie.get("title")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Category</p>
                <p className="price-livedetail">{cookie.get("category")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Email</p>
                <p className="price-livedetail">{cookie.get("email")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Total</p>
                <p className="price-livedetail">
                  Rp. {cookie.get("price")} / $ {cookie.get("dollar")}
                </p>
              </div>
              <div class="row-md-4 d-flex justify-content-between">
                <div class="col text-white">1. Choose Payment</div>
              </div>
              <div class="row-md-4 d-flex justify-content-between">
                <div className="d-flex flex-column pt-2">
                  <div className="btn-gradi">
                    <Link to="/ewallet">
                      <div className="d-flex justify-content-between text-white">
                        <span>E-wallet</span>
                        <span>
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                    </Link>
                  </div>
                  {/* <div className="btn-gradi">
                    <div
                      className="d-flex justify-content-between"
                      onClick={this.handleCC}
                    >
                      <span>Credit Card</span>
                      <span>
                        <i class="fas fa-arrow-right"></i>
                      </span>
                    </div>
                  </div> */}
                  <div className="btn-gradi">
                    <Link to="/va">
                      <div className="d-flex justify-content-between text-white">
                        <span>Virtual Account</span>
                        <span>
                          <i class="fas fa-arrow-right"></i>
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="btn-gradi">
                    <div
                      className="d-flex justify-content-between"
                      onClick={this.handlePayPal}
                    >
                      <span>Paypall</span>
                      <span>
                        <i class="fas fa-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                  <div className="btn-gradi">
                    <div
                      className="d-flex justify-content-between"
                      onClick={this.handleQris}
                    >
                      <span>QRIS</span>
                      <span>
                        <i class="fas fa-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <FooterMobile />
      </div>
    );
  }
}

export default Paynow;
