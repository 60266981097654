import React, { useRef } from "react";
import Axios from "axios";
import { API_URL } from "../../../config/common/baseurl";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
  CloseButton,
} from "react-bootstrap";
import "../App/App.css";
import Footer from "../../organism/Footer";
// import Cookies from 'cookie-universal'

class Forgotpass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      toLogin: false,
      changePassword: [false, ""],
      loginError: [false, ""],
    };
  }

  handleChangePass = () => {
    let email = this.refs.email.value;
    let password = this.refs.password.value;
    // console.log(email, password)

    if (!email || !password)
      return this.setState({ loginError: [true, "Please Input All Form"] });

    Axios.put(`${API_URL}/member/forgot-password`, {
      email: email,
      password: password,
    })
      .then((res) => {
        // console.log(res)
        alert("password has changed");
        this.setState({ toLogin: true });
      })

      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { loginError, toLogin, changePassword, visible } = this.state;
    if (toLogin) return <Redirect to="/login" />;
    return (
      <div>
        <div style={{ backgroundColor: "black" }}>
          <Row>
            <Col className="d-flex justify-content-between" sm={12}>
              <Link
                to="/login"
                style={{
                  color: "white",
                  fontFamily: "microextend",
                  margin: "20px",
                  cursor: "pointer",
                }}
              >
                <i className="fas fa-chevron-left"></i> Back
              </Link>
            </Col>
          </Row>
        </div>
        <Container
          fluid
          style={{ minHeight: "80vh", backgroundColor: "black" }}
        >
          <Row className="justify-content-md-center">
            <Col md={3}>
              <h6
                style={{ backgroundColor: "black", fontSize: "15px" }}
                className="d-flex justify-content-center mx-auto my-auto"
              >
                Please input your email and new password
              </h6>
              <Form className="mt-5">
                <Form.Group>
                  <Form.Label>
                    <h6>Email</h6>
                  </Form.Label>
                  <Form.Control
                    ref="email"
                    type="email"
                    placeholder="email@email.com"
                    style={{
                      color: "white",
                      backgroundColor: "black",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                  />
                  <Form.Label className="mt-3">
                    <h6>New Password</h6>
                  </Form.Label>
                  <Form.Control
                    ref="password"
                    type={visible ? "text" : "password"}
                    placeholder="minimum 6 character"
                    style={{
                      color: "white",
                      backgroundColor: "black",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                  />
                  <Form.Text className="text-muted">
                    <Row>
                      <Col
                        onClick={() => this.setState({ visible: !visible })}
                        className="inputicon"
                        md={1}
                        style={{ cursor: "pointer", marginLeft: "auto" }}
                      >
                        {visible ? (
                          <i className="fas fa-eye-slash"></i>
                        ) : (
                          <i className="fas fa-eye"></i>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}></Col>
                      {/* <Col md={4} style={{ cursor: 'pointer', }}>Lupa Password? </Col> */}
                    </Row>
                  </Form.Text>
                </Form.Group>
                <Button
                  onClick={this.handleChangePass}
                  style={{
                    backgroundColor: "#faa21b",
                    border: "none",
                    fontFamily: "microextend",
                    borderRadius: "10px",
                    cursor: "pointer",
                    marginTop: "15px",
                  }}
                  block
                >
                  Change Password
                </Button>
                {/* <p className='text-center mt-5'>Another way:</p> */}
                {/* <StyledFirebaseAuth
                                    uiConfig={this.uiConfig}
                                    firebaseAuth={firebase.auth()}
                                /> */}
                <div className="d-flex justify-content-center mt-3 pb-5">
                  <p>Dont have an account?</p>
                  <Link style={{ textDecoration: "none" }} to="/register">
                    <h6 className="pt-1 pl-3">Register</h6>
                  </Link>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Forgotpass;
