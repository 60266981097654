import React from 'react'
import axios from 'axios'
import Footer from '../../organism/Footer'
import FooterMobile from '../../organism/FooterMobile'
import NavbarBack from '../../organism/NavbarBack'
import { Redirect } from 'react-router-dom'
import { API_URL } from '../../../config/common/baseurl'
import { ICPREMIUM } from '../../../assets/icon/buttom-bar-mobile/index'
import { Modal, Row, Col, Form, Button, Navbar } from 'react-bootstrap'
import { ICSHAREXL, FAVORITE } from '../../../assets/icon'
import {
    FacebookIcon,
    FacebookShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    TwitterIcon,
    TwitterShareButton,
    TelegramIcon,
    TelegramShareButton
} from 'react-share'
import Cookise from 'cookie-universal'

const cookie = new Cookise()

class DetailVideoOne extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            videoHighlight: []
        }
    }
    componentDidMount = () => {
        const token = {
            headers: { access_token: cookie.get('access_token') }
        }
        const data = { category: "one on one" }
        axios.post(`${API_URL}/video/category`, data, token)
            .then(async (res) => {
                // console.log('data', res.data)
                const b = res.data.data.find(x => x._id === this.props.match.params.id);
                // console.log('data', b)
                this.setState({ videoHighlight: b })
            })
            .catch((err) => {
                console.log(err)

            })
    }


    render() {   
        return (
            <>
                <NavbarBack />
                <div className='container-fluid'>
                        <div className='row justify-content-center align-items-center'>
                        <div className='card-article col-md-4'>
                            <div className='card text-center bg-dark text-decoration-none'>
                                <iframe src={this.state.videoHighlight.video} alt='onepict' className='card-img img-fluid' />
                                <div className='card-body'>
                                    <h5 className='card-title text-left'>{this.state.videoHighlight.title}</h5>
                                    <div className='d-flex justify-content-between card-text text-muted'>
                                        <div className='d-flex flex-column'>
                                        <span className='text-muted'>{this.state.videoHighlight.name}</span>
                                        <span className='text-muted'>{this.state.videoHighlight.role}</span>
                                            </div>
                                        <p className='text-muted'>{this.state.videoHighlight.dateString}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <FooterMobile />
            </>
        )
    }
}

export default DetailVideoOne