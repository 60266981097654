export const login = (data) => {
    return{
        type: 'LOG_IN',
            payload: data
    }
}

export const register = (data) => {
    return{
        type: 'REGISTER',
        payload: data
    }
} 

export const logout = () =>{
    return{
        type:'LOG_OUT'
    }
}

export const getProfile = (data) =>{
    return{
        type:'USER_PROFILE',
        payload : data
    }
}