import React from 'react'
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom'
import { API_URL } from '../../../config/common/baseurl'
import FooterMobile from '../../organism/FooterMobile'
import NavbarBack from '../../organism/NavbarBack'
import Footer from '../../organism/Footer'
import '../App/App.css'
import { FOUR } from '../../../assets/image/update'
import Cookies from 'cookie-universal'
const cookie = new Cookies()

class FavoritePage extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            dataFavArticle: [],
            dataFavVideo: [],
            dataFavPicture: []
        }
    }
    getProfile = () => {
        const headers = {
            access_token: cookie.get('access_token')
        };
        axios.get(`${API_URL}/member/detail`, { headers })
            .then((res) => {
                // console.log('data ygada d APP', res.data)
                // console.log('data article', res.data.favArticle)
                this.setState({ dataFavArticle: res.data.favArticle })
                this.setState({ dataFavPicture: res.data.favPhoto })
                this.setState({ dataFavVideo: res.data.favVideo })
                this.setState({ dataFavStreaming: res.data.favStreaming })
                // this.props.getProfile(res.data.data)

            }
            )
            .catch((err) => console.log(err))
    }
    componentDidMount() {
        this.getProfile()
    }

    renderFavArticle = () => {
        return this.state.dataFavArticle.map((val, i) => {
            return (
                <div className='container-fluid'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='card-article col-md-3'>
                            <div className='card text-center bg-dark '>
                                <img src={val.picture} alt='onepict' className='card-img img-fluid' key={i} />
                                <div className='card-body'>
                                    <h5 className='card-title text-left' >{val.title}</h5>
                                    <p className='card-text text-left text-muted'>{val.dateString}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }
    renderFavPicture = () => {
        return this.state.dataFavPicture.map((val, i) => {
            return (
                <div className='container-fluid'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='card-article col-md-3'>
                            <div className='card text-center bg-dark '>
                                <img src={val.photo[0]} alt='onepict' className='card-img img-fluid' key={i} />
                                <div className='card-body'>
                                    <h5 className='card-title text-left' >{val.title}</h5>
                                    <p className='card-text text-left text-muted'>{val.dateString}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }
    renderFavVideo = () => {
        return this.state.dataFavVideo.map((val, i) => {
            return (
                <div className='container-fluid'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='card-article col-md-3'>
                            <div className='card text-center bg-dark '>
                                <img src={val.video} alt='onepict' className='card-img img-fluid' key={i} />
                                <div className='card-body'>
                                    <h5 className='card-title text-left' >{val.title}</h5>
                                    <p className='card-text text-left text-muted'>{val.dateString}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        // console.log('hasil', this.state.dataFavArticle)
        if (cookie.get('access_token') == null || cookie.get('access_token').length < 200 || cookie.get('access_token').length > 250) {
            return <Redirect to='/login' />
        }
        return (
            <div>
                <NavbarBack />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col'>
                            <div>
                                <h2>Favorite</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col d-flex justify-content-center'>
                            <div>
                                <h6>Article</h6>
                                {this.renderFavArticle()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col d-flex justify-content-center'>
                            <div>
                                <h6>Picture</h6>
                                {this.renderFavPicture()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col d-flex justify-content-center'>
                            <div>
                                <h6>Video</h6>
                                {this.renderFavVideo()}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <FooterMobile />
            </div>
        )
    }
}

export default FavoritePage