import React from 'react';
import ReactDOM from 'react-dom';
import App from './container/pages/App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import firebase from './config/firebase';
import { BrowserRouter, Route } from 'react-router-dom'
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import allReducers from './config/reducer'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'


// console.log('config firebase =>',firebase)

const globalState = createStore(allReducers)
// console.log('globalstate =>', globalState )
globalState.subscribe(() => 
  // console.log('globalstate:', 
  globalState.getState()
  // )
  )

ReactDOM.render(
  <Provider store={globalState}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
