import React from 'react'
import '../../pages/App/App.css'
import { Link } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import { LIVE } from '../../../assets/image/live'
// import { LIVEGP, LIVEUFC, LIVESOCCER } from '../../../assets/image/live'

class Livecomp extends React.Component {
    render() {
        return (
            <section className='live'>
                <div className='container py-5'>
                    <div className='row justify-content-md-center'>
                        <div className='col-md-4 mt-3 mb-0'>
                            <h2 className='text-center py-4'>
                                LIVE TODAY
                            </h2>
                        </div>
                    </div>
                    <Link to='/livedetail' className='d-flex justify-content-center'>
                        <Image src={LIVE.default} fluid />
                    </Link>
                </div>
            </section>
        )
    }
}

export default Livecomp
