import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarBack from "../../organism/NavbarBack";
import { API_URL } from "../../../config/common/baseurl";
import { ICPREMIUM } from "../../../assets/icon/buttom-bar-mobile/index";
import { ICSHAREXL, FAVORITE } from "../../../assets/icon";
import Cookise from "cookie-universal";
import { Modal } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

const cookie = new Cookise();

const domain = "https://mpro.world/";

class DetailLibraryArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDetailArticle: [],
      favourite: false,
      idArticle: "",
      showShare: false,
      statusLike: "",
    };
  }

  componentDidMount = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const data = { role: "MPRO Contributor" };
    // console.log('ini',this.props.match.params.id)
    axios
      .post(`${API_URL}/article/contributor-role`, data, token)
      .then(async (res) => {
        // console.log('data', res.data.data)
        const b = res.data.data.find(
          (x) => x._id === this.props.match.params.id
        );
        // console.log('b =>', b);
        // if (b.like !== 0) {
        //     this.setState({ favourite: true })
        // }
        this.setState({ idArticle: b._id });
        let dataDetailArticle = [];
        for (var i = 0; i < res.data.data.length; i++) {
          // console.log('b d dalam for', b)
          if (b[i]) dataDetailArticle.push(b[i]);
          // console.log('b sesudah d loop', b)
          this.setState({
            dataDetailArticle: b,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  handleShare = () => {
    this.setState({ showShare: true });
  };

  handleFavourite = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    // console.log('cek token', token)
    axios
      .put(`${API_URL}/article/favorite/${this.state.idArticle}`, null, token)
      .then((res) => {
        // console.log('cekfav', res.data)
        if (this.state.statusLike) {
          this.setState({ statusLike: false });
        } else {
          this.setState({ statusLike: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleShareDetail = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };

    axios
      .put(`${API_URL}/article/share/${this.state.idArticle}`, null, token)
      .then((res) => {
        // console.log('share', res)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    // console.log('data article', this.state.dataDetailArticle)

    const { dataDetailArticle, favourite, showShare, statusLike } = this.state;
    // const a = ...dataDetailArticle.contributor
    const data = this.state.dataDetailArticle.article;
    // console.log('a=>', a)
    // console.log('nama kontributr', dataDetailArticle.contributor)
    return (
      <div>
        <NavbarBack />
        <div className="container-fluid">
          <div className="row align-items-center flex-column">
            <div className="col-md-4">
              <img
                className="live-picture"
                src={dataDetailArticle.picture}
                alt="live-picture"
              />
              <div className=" pt-2">
                <h5 className="title-livedetail">{dataDetailArticle.title}</h5>
              </div>
            </div>
            <div className="col-md-4">
              <p className="date-livedetail text-muted">
                {dataDetailArticle.dateString}
              </p>
            </div>
            <div className="d-flex justify-content-between col-md-4">
              <div className="d-flex"></div>
              <div className="d-flex">
                <div className="px-2" onClick={this.handleShare}>
                  <img src={ICSHAREXL.default} alt="icon share" />
                  <p className="ictitle-livedetail text-muted">Share</p>
                </div>
                <div onClick={this.handleFavourite}>
                  {statusLike ? (
                    <i className="fas fa-heart pl-1"></i>
                  ) : (
                    <img
                      src={FAVORITE.default}
                      className="icfav-livedetail"
                      alt="icon share"
                    />
                  )}
                  <p className="ictitle-livedetail text-muted">Favorite</p>
                </div>
              </div>
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: data }}
              className="date-livedetail col-md-4"
              style={{ opacity: "0.8" }}
            />
          </div>
        </div>
        <FooterMobile />
        <Footer />
        <Modal
          show={showShare}
          onHide={() => this.setState({ popUpShare: true })}
        >
          <Modal.Header
            style={{ backgroundColor: "#040124" }}
            onClick={() => this.setState({ showShare: false })}
            closeButton
          >
            <h5>SHARE</h5>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#040124", minHeight: "50vh" }}>
            <div className="d-flex flex-column justify-content-around">
              <FacebookShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <FacebookIcon className="rounded"></FacebookIcon>
                <h5 className="ml-2 my-auto"> facebook </h5>
              </FacebookShareButton>
              <WhatsappShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <WhatsappIcon className="rounded"></WhatsappIcon>
                <h5 className="ml-2 my-auto"> WhatsaApp </h5>
              </WhatsappShareButton>
              <TelegramShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <TelegramIcon className="rounded"></TelegramIcon>
                <h5 className="ml-2 my-auto"> Telegram </h5>
              </TelegramShareButton>
              <TwitterShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <TwitterIcon className="rounded"></TwitterIcon>
                <h5 className="ml-2 my-auto"> Twitter </h5>
              </TwitterShareButton>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DetailLibraryArticle;
