import React from "react";
import axios from "axios";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarBack from "../../organism/NavbarBack";
import { LIVE } from "../../../assets/image/live";
import { ICPREMIUM } from "../../../assets/icon/buttom-bar-mobile/index";
import { ICSHAREXL, FAVORITE } from "../../../assets/icon";
import { Link, Redirect } from "react-router-dom";
import { API_URL } from "../../../config/common/baseurl";
import Cookise from "cookie-universal";
import { Modal } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

const cookie = new Cookise();

const domain = "https://mpro.world/";

class DetailLiveStreaming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDetaills: [],
      toPayment: false,
      trans_Id: "",
      toWatchStream: false,
      showShare: false,
      idArticle: "",
      statusLike: "",
    };
  }

  componentDidMount() {
    // console.log('ini',this.props.match.params.id)
    axios
      .get(`${API_URL}/streaming/next`)
      .then(async (res) => {
        // console.log(res.data)
        const b = res.data.data.find(
          (x) => x._id === this.props.match.params.id
        );
        // console.log('b =>', b);
        if (b.like !== 0) {
          this.setState({ favourite: true });
        }
        let dataDetaills = [];
        for (var i = 0; i < res.data.data.length; i++) {
          // console.log('b d dalam for', b)
          if (b[i]) dataDetaills.push(b[i]);
          // console.log('b sesudah d loop', b)
          // console.log('ID', b._id)
          this.setState({
            dataDetaills: b,
            id: b._id,
          });
        }
      })
      .catch((err) => console.log(err));
  }

  handlePayment = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    axios
      .post(
        `${API_URL}/streaming/token-add/${this.state.id}`,
        {
          ip: "192.168.11.131",
        },
        token
      )
      .then((res) => {
        // console.log('data', res.data.data)

        this.setState({ trans_Id: res.data.data.transId });
        if (res.data.success === false && res.status === 200) {
          cookie.set("trans_id", res.data.data.transId, { path: "/" });
          cookie.set("title", res.data.streaming.title, { path: "/" });
          cookie.set("time", res.data.streaming.time, { path: "/" });
          cookie.set("date", res.data.streaming.dateString, { path: "/" });
          cookie.set("category", res.data.data.title, { path: "/" });
          cookie.set("email", res.data.user.email, { path: "/" });
          cookie.set("category", res.data.streaming.competition.name, {
            path: "/",
          });
          cookie.set("price", this.state.dataDetaills.rupiahPrice, {
            path: "/",
          });
          cookie.set("dollar", this.state.dataDetaills.dollarPrice, {
            path: "/",
          });
          this.setState({ toPayment: true });
          return alert(res.data.message);
        } else if (res.data.success === true) {
          cookie.set("link", res.data.data);
          this.setState({ toWatchStream: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleShare = () => {
    this.setState({ showShare: true });
  };

  handleShareDetail = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    axios
      .put(`${API_URL}/article/share/${this.state.idArticle}`, null, token)
      .then((res) => {
        // console.log('share', res)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleFavourite = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };

    // console.log('cek token', token)
    axios
      .put(`${API_URL}/picture/favorite/${this.state.idArticle}`, null, token)
      .then((res) => {
        // console.log('cekfav', res.data)
        if (this.state.statusLike) {
          this.setState({ statusLike: true });
        } else {
          this.setState({ statusLike: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    // console.log('data detail ls', this.state.dataDetaills)
    // console.log('id d state', this.state.trans_Id)
    const { dataDetaills, toPayment, toWatchStream, showShare, statusLike } =
      this.state;
    if (toWatchStream) return <Redirect to="/watchls" />;
    if (toPayment) return <Redirect to="/paynow" />;

    const data = this.state.dataDetaills.description;
    return (
      <div>
        <NavbarBack />
        <div className="container-fluid">
          <div className="row align-items-center flex-column">
            <div className="col-md-4 d-flex flex-column">
              <img
                className="live-picture"
                src={dataDetaills.thumbnail}
                alt="live-picture"
              />
              <div className="pr-2">
                <img fluid src={ICPREMIUM.default} alt="icon-premium" />
              </div>
              <div className=" pt-2">
                <h5 className="title-livedetail">{dataDetaills.title}</h5>
              </div>
            </div>
            <div className="col-md-4">
              <p className="date-livedetail text-muted">
                {dataDetaills.dateString}
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4 ">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <h2 className="price-livedetail pt-3">
                    RP. {dataDetaills.rupiahPrice} / ${" "}
                    {dataDetaills.dollarPrice}{" "}
                  </h2>
                  <div className="d-flex">
                    <div className="px-2" onClick={this.handleShare}>
                      <img src={ICSHAREXL.default} alt="icon share" />
                      <p className="ictitle-livedetail text-muted">Share</p>
                    </div>
                    <div onClick={this.handleFavourite}>
                      {statusLike ? (
                        <i className="fas fa-heart pl-1"></i>
                      ) : (
                        <img
                          src={FAVORITE.default}
                          className="icfav-livedetail"
                          alt="icon share"
                        />
                      )}
                      <p className="ictitle-livedetail text-muted">Favorite</p>
                    </div>
                  </div>
                </div>
                <p className="date-livedetail text-muted">Information</p>
                <p
                  dangerouslySetInnerHTML={{ __html: data }}
                  className="date-livedetail"
                  style={{ opacity: "0.8" }}
                />
                <div className="warning-livedetail text-center">
                  <p>
                    "Premium content can only be accessed if you have purchased
                    access"
                  </p>
                </div>
                <button className="btn-grad mt-5" onClick={this.handlePayment}>
                  {" "}
                  WATCH{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <FooterMobile />
        <Footer />

        <Modal
          show={showShare}
          onHide={() => this.setState({ popUpShare: true })}
        >
          <Modal.Header
            style={{ backgroundColor: "#040124" }}
            onClick={() => this.setState({ showShare: false })}
            closeButton
          >
            <h5>SHARE</h5>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#040124", minHeight: "50vh" }}>
            <div className="d-flex flex-column justify-content-around">
              <FacebookShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <FacebookIcon className="rounded"></FacebookIcon>
                <h5 className="ml-2 my-auto"> facebook </h5>
              </FacebookShareButton>
              <WhatsappShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <WhatsappIcon className="rounded"></WhatsappIcon>
                <h5 className="ml-2 my-auto"> WhatsaApp </h5>
              </WhatsappShareButton>
              <TelegramShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <TelegramIcon className="rounded"></TelegramIcon>
                <h5 className="ml-2 my-auto"> Telegram </h5>
              </TelegramShareButton>
              <TwitterShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <TwitterIcon className="rounded"></TwitterIcon>
                <h5 className="ml-2 my-auto"> Twitter </h5>
              </TwitterShareButton>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DetailLiveStreaming;
