import React from "react";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarBack from "../../organism/NavbarBack";
import { ICSHAREXL, FAVORITE } from "../../../assets/icon";
import { ONE } from "../../../assets/image/podcast";
import Cookise from "cookie-universal";
import axios from "axios";
import { API_URL } from "../../../config/common/baseurl";
import { Modal } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

const cookie = new Cookise();

const domain = "https://mpro.world/";

class Oneonpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showShare: false,
    };
  }

  handleShare = () => {
    this.setState({ showShare: true });
  };

  handleShareDetail = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    axios
      .put(`${API_URL}/article/share/${this.state.idArticle}`, null, token)
      .then((res) => {
        // console.log('share', res)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { showShare } = this.state;
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="big-container">
        <NavbarBack />
        {/* <div className='text-center'>
            <iframe  src='https://six1.zqprem.com/zqscore/embed.html' />
                </div> */}
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="d-flex justify-content-center">
              <iframe src="https://six1.zqprem.com/zqscore/embed.html" />

              {/* <img fluid
                            src={ONE.default}
                            alt='detail-live' /> */}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center ">
            <div className="col-md-4 d-flex justify-content-center">
              <div className=" pt-2">
                <h5 className="title-livedetail text-center">
                  The impact of the pandemic on athletes - One on One Eps. 01
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <p className="date-livedetail text-muted">20.00 - 10 juni 2021</p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4 d-flex justify-content-between">
              <h2 className="price-livedetail pt-2"></h2>
              <div className="d-flex">
                <div className="px-3" onClick={this.handleShare}>
                  <img src={ICSHAREXL.default} alt="icon share" />
                  <p className="ictitle-livedetail text-muted">Share</p>
                  {/* <img src={FAVORITEXL.default} alt='icon share'/> */}
                </div>
                <div className="px-1">
                  <img
                    src={FAVORITE.default}
                    className="icfav-livedetail"
                    alt="icon share"
                  />
                  <p className="ictitle-livedetail text-muted">Favorite</p>
                  {/* <img src={FAVORITEXL.default} alt='icon share'/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <p className="date-livedetail text-muted">Information</p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <p className="date-livedetail">
                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                used in laying out print, graphic or web designs. The passage is
                attributed to an unknown typesetter in the 15th century who is
                thought to have scrambled parts of Cicero's De Finibus Bonorum
                et Malorum for use in a type specimen book
              </p>
              <div className="py-5 px-5">
                <Link to="./login">
                  <button className="bton px-2 py-1 btn-block" block>
                    Login to watch
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='col bg-success'>
                                
                            </div> */}
        <Footer />
        <FooterMobile />
        <Modal
          show={showShare}
          onHide={() => this.setState({ popUpShare: true })}
        >
          <Modal.Header
            style={{ backgroundColor: "#040124" }}
            onClick={() => this.setState({ showShare: false })}
            closeButton
          >
            <h5>SHARE</h5>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#040124", minHeight: "50vh" }}>
            <div className="d-flex flex-column justify-content-around">
              <FacebookShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <FacebookIcon className="rounded"></FacebookIcon>
                <h5 className="ml-2 my-auto"> facebook </h5>
              </FacebookShareButton>
              <WhatsappShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <WhatsappIcon className="rounded"></WhatsappIcon>
                <h5 className="ml-2 my-auto"> WhatsaApp </h5>
              </WhatsappShareButton>
              <TelegramShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <TelegramIcon className="rounded"></TelegramIcon>
                <h5 className="ml-2 my-auto"> Telegram </h5>
              </TelegramShareButton>
              <TwitterShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <TwitterIcon className="rounded"></TwitterIcon>
                <h5 className="ml-2 my-auto"> Twitter </h5>
              </TwitterShareButton>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Oneonpage;
