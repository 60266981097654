import React, { Component } from 'react'

export class Trendingpage extends Component {
    render() {
        return (
            <div>
                ini trending page 
            </div>
        )
    }
}

export default Trendingpage
