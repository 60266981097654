import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Footer from '../../organism/Footer'
import FooterMobile from '../../organism/FooterMobile'
import NavbarBack from '../../organism/NavbarBack'

class ReturnPolicy extends React.Component {
    
    constructor(props) {
        super(props);

        this.email_contact = "mpro@gmail.com";

        this.address_contact = "jalan lingkar luar barat no. 23 B-C";
    }
    render() {
        return (
            <div style={{ backgroundColor: 'black', minHeight: '100vh', padding: '20px' }}>
                <NavbarBack />
                <Container>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <h1 style={{ fontSize:'16px',}} className='py-3'>
                                REFUND AND RETURN POLICY
                            </h1>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <h2 style={{ fontSize:'16px',}} className='py-3'>
                            Overview
                            </h2>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            Our refund and returns policy lasts 30 days. If 30 days have passed since your purchase, we can’t offer you a full refund or exchange.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                        <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            Several types of goods are exempt from being returned. Perishable goods such as food, flowers, newspapers or magazines cannot be returned. We also do not accept products that are intimate or sanitary goods, hazardous materials, or flammable liquids or gases.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                Additional non-returnable items: <br/>

                                1. Gift cards. <br/>
                                2. Downloadable software products. <br/>
                                3. Some health and personal care items.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            To complete your return, we require a receipt or proof of purchase.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            Please do not send your purchase back to the manufacturer.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            There are certain situations where only partial refunds are granted:<br/>
                            <ol>
                                <li>Book with obvious signs of use</li>
                                <li>CD, DVD, VHS tape, software, video game, cassette tape, or vinyl record that has been opened.</li>
                                <li>Any item not in its original condition, is damaged or missing parts for reasons not due to our error.</li>
                                <li>Any item that is returned more than 30 days after delivery</li>
                            </ol>
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <h2 style={{ fontSize:'16px',}} className='py-3'>
                            Refunds
                            </h2>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            <strong>Late or missing refunds</strong>
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            If you haven’t received a refund yet, first check your bank account again.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            Then contact your credit card company, it may take some time before your refund is officially posted.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            Next contact your bank. There is often some processing time before a refund is posted.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            If you’ve done all of this and you still have not received your refund yet, please contact us at {this.email_contact}.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            <strong>Sale items</strong>
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            Only regular priced items may be refunded. Sale items cannot be refunded.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <h2 style={{ fontSize:'16px',}} className='py-3'>
                            Exchanges
                            </h2>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an email at {this.email_contact} and send your item to: {this.address_contact}.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <h2 style={{ fontSize:'16px',}} className='py-3'>
                            Gifts
                            </h2>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            If the item was marked as a gift when purchased and shipped directly to you, you’ll receive a gift credit for the value of your return. Once the returned item is received, a gift certificate will be mailed to you.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            If the item wasn’t marked as a gift when purchased, or the gift giver had the order shipped to themselves to give to you later, we will send a refund to the gift giver and they will find out about your return.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <h2 style={{ fontSize:'16px',}} className='py-3'>
                            Shipping returns
                            </h2>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            To return your product, you should mail your product to: {this.address_contact}.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            Depending on where you live, the time it may take for your exchanged product to reach you may vary.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            If you are returning more expensive items, you may consider using a trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <h2 style={{ fontSize:'16px',}} className='py-3'>
                            Need help?
                            </h2>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                            Contact us at {this.email_contact} for questions related to refunds and returns.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
                <Footer />
                <FooterMobile />
            </div>
        )
    }
}

export default ReturnPolicy