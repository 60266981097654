import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../organism/Footer'
import FooterMobile from '../../organism/FooterMobile'
import NavbarBack from '../../organism/NavbarBack'
import { BOXINGHL } from '../../../assets/image/update'
import { ICSHAREXL, FAVORITE } from '../../../assets/icon'
import { Modal } from 'react-bootstrap'
import {
    FacebookIcon,
    FacebookShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    TwitterIcon,
    TwitterShareButton,
    TelegramIcon,
    TelegramShareButton
} from 'react-share'

class HighlightPage extends React.Component{
    render(){
        return(
            <div className='big-container'>
            <NavbarBack />
            <div className='container-fluid'>
                <div className='row justify-content-md-center'>
                    <div className='d-flex justify-content-md-center col-md-4'>
                        <img fluid
                            src={BOXINGHL.default}
                            alt='detail-live' />
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row justify-content-md-center '>
                    <div className='col-md-4 d-flex justify-content-center'>
                        <div className=' pt-2'>
                            <h5 className='title-livedetail text-center'> MAYWEATHER VS LOGAN PAUL</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-4'>
                        <p className='date-livedetail text-muted'>20.00 - 10 juni 2021</p>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-4 d-flex justify-content-between'>
                        <h2 className='price-livedetail pt-2'></h2>
                        <div className='d-flex'>
                            <div className='px-2'>
                                <img src={ICSHAREXL.default} alt='icon share' />
                                <p className='ictitle-livedetail text-muted'>Share</p>
                                {/* <img src={FAVORITEXL.default} alt='icon share'/> */}
                            </div>
                            <div>
                                <img src={FAVORITE.default} className='icfav-livedetail' alt='icon share' />
                                <p className='ictitle-livedetail text-muted'>Favorite</p>
                                {/* <img src={FAVORITEXL.default} alt='icon share'/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-4'>
                        <p className='date-livedetail text-muted'>Information</p>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-4'>
                        <p className='date-livedetail'>
                            Lorem ipsum, or lipsum as it is sometimes known, 
                            is dummy text used in laying out print, graphic or web designs.
                            The passage is attributed to an unknown typesetter in the 15th 
                            century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book
                        </p>
                        <div className='pt-2 pb-5'>
                            <Link to='./login'> 
                            <button className='bton px-2 py-1 btn-block' block>Login to watch</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='col bg-success'>
                                
                            </div> */}
            <Footer />
            <FooterMobile />
        </div>
        )
    }
}

export default HighlightPage

