// import React from "react";

// class Tabs extends React.Component{
//     constructor(props) {
//         super(props);
//         this.state = {
//             isToggleOn: true
//         //     dataTab: [{
//         //         name : joni;
//         //         // content : lorem ipsum lur domet
//         //     }]
//         // }
//     }
//     render(){
//         return(
//             <section>
//                 <h2>Tabs</h2>
//             </section>
//         )
//     }
// }

// export default Tabs