import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LOGOIMGXL } from "../../../assets/logo/index";
import NavbarBack from "../../organism/NavbarBack";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";

class AboutUs extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: "black" }}>
        <NavbarBack />
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <h1>ABOUT US</h1>
              <div className=" p-5 bg-dark my-3 rounded">
                <img src={LOGOIMGXL.default} akt="logo" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container style={{ minHeight: "50vh" }}>
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <p>
                In an era, where smart TVs and smart appliances are entering
                into the modern lifestyle at a constant pace, most of the big
                brands are launching their proprietary apps to connect to
                viewers and their potential customers with great ease. Thereby,
                the smart TV app development business is growing rapidly for
                ensuring seamless connectivity across various devices and
                platforms.
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={4}>
              <p>
                MPRO app services is a platform that provides the ultimate and
                most exclusive sports contents. The platform is very a promising
                tool to promote, enhance visibility, and help in reaching out to
                the potential targets of the business in national, regional, and
                even international levels.
              </p>
            </Col>
          </Row>
        </Container>
        <Footer />
        <FooterMobile />
      </div>
    );
  }
}

export default AboutUs;
