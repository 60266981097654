import React from 'react'
import './button.css'

class Button extends React.Component{
    render(){
        return(
                <button className='bton px-3 py-2 btn-block' block>SIGN UP</button>
        )
    }
}

export default Button