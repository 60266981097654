import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { API_URL } from "../../../config/common/baseurl";
import "./App.css";
import { connect } from "react-redux";
import { getProfile } from "../../../config/action";
import Home from "../Home";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Live from "../Live";
import Library from "../Library";
import Video from "../Video";
import login from "../Login";
import Pralogin from "../Pralogin";
import HighlightPage from "../HighlightPage";
import Subscribenow from "../Subscribenow";
import Vouchercode from "../Vouchercode";
import Register from "../Register";
import AboutUs from "../AboutUs";
import Contactus from "../ContactUs";
import TermCondition from "../TermCondition";
import Privacypolicy from "../PrivacyPolicy";
import Oneonpage from "../Oneonpage";
import Trendingpage from "../Trendingpage";
import Categorypage from "../Categorypage";
import Topcomppage from "../Topcomppage";
import Article from "../Article";
import Profile from "../Profile";
import Livedetail from "../Livedetail";
import Paynow from "../Paynow";
import Categorydetail from "../Categorydetail";
import PurchaseHistory from "../Purchasehistory";
import Paymentsuccess from "../Paymentsuccess";
import FavoritePage from "../Favorite";
import DetailArticle from "../DetailArticle";
import LandingPage from "../LandingPage";
import DetailLiveStreaming from "../DetailLiveStreaming";
import LibraryArticle from "../Library-Articles";
import LibraryPhoto from "../Library-Photo";
import VideoOneonone from "../VideoOneonone";
import VideoCommunity from "../VideoCommunity";
import DetailLibraryArticle from "../DetailLibraryArticle";
import DetailLibraryVideos from "../DetailLibraryVideos";
import PaymentEwallet from "../PaymentEwallet";
import WatchLiveStreaming from "../WatchLiveStreaming";
import PaymentVA from "../PaymentVA";
import Cookies from "cookie-universal";
import Pictures from "../Pictures";
import PageNotFound from "../404PageNotFound";
import Forgotpass from "../ForgotPassword";
import DetailVideo from "../DetailVideo";
import DetailVideoCommunity from "../DetailVideoCommunity";
import DetailVideoOne from "../DetailVideoOne";
import PaymentPaypal from "../PaymantPaypal";
import VideoPlay from "../VideoPlay";
import ReturnPolicy from "../ReturnsPolicy";

const cookie = new Cookies();

class App extends React.Component {
  getProfile = () => {
    const headers = {
      access_token: cookie.get("access_token"),
    };
    axios
      .get(`${API_URL}/member/detail`, { headers })
      .then((res) => {
        // console.log('data ygada d APP', res.data.data)
        this.props.getProfile(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  componentDidMount() {
    this.getProfile();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/home" component={Home} />
          <Route path="/article" component={Article} />
          <Route path="/live" component={Live} />
          <Route path="/library" component={Library} />
          <Route path="/video" component={Video} />
          <Route path="/pralogin" component={Pralogin} />
          <Route path="/login" component={login} />
          <Route path="/register" component={Register} />
          <Route path="/profile" component={Profile} />
          {/* <Route path='/subscribenow' component={Subscribenow} /> */}
          {/* <Route path='/vouchercode' component={Vouchercode} /> */}
          {/* <Route path='/highlightpage' component={HighlightPage} /> */}
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/contactus" component={Contactus} />
          <Route path="/termcondition" component={TermCondition} />
          <Route path="/privacypolicy" component={Privacypolicy} />
          <Route path="/returnpolicy" component={ReturnPolicy} />
          {/* <Route path='/oneonpage' component={Oneonpage} /> */}
          {/* <Route path='/trendingpage' component={Trendingpage} /> */}
          <Route path="/categorypage/:id" component={Categorypage} />
          <Route path="/categorydetail" component={Categorydetail} />
          {/* <Route path='/topcomppage' component={Topcomppage} /> */}
          {/* <Route path='/livedetail' component={Livedetail} /> */}
          <Route path="/paynow" component={Paynow} />
          <Route path="/purchasehistory" component={PurchaseHistory} />
          {/* <Route path='/paymentsuccess' component={Paymentsuccess} /> */}
          <Route path="/favoritepage" component={FavoritePage} />
          <Route path="/detailarticle/:id" component={DetailArticle} />
          <Route
            path="/detaillibraryarticle/:id"
            component={DetailLibraryArticle}
          />
          <Route path="/detaillibraryvid/:id" component={DetailLibraryVideos} />
          <Route path="/detaills/:id" component={DetailLiveStreaming} />
          <Route path="/libraryarticle" component={LibraryArticle} />
          <Route path="/libraryphoto" component={LibraryPhoto} />
          <Route path="/videooneonone" component={VideoOneonone} />
          <Route path="/videocommunity" component={VideoCommunity} />
          <Route path="/ewallet" component={PaymentEwallet} />
          <Route path="/va" component={PaymentVA} />
          <Route path="/paypal" component={PaymentPaypal} />
          <Route path="/watchls" component={WatchLiveStreaming} />
          <Route path="/pictures/:id" component={Pictures} />
          <Route path="/forgotpass" component={Forgotpass} />
          <Route path="/detailvideo/:id" component={DetailVideo} />
          <Route path="/detailvideocom/:id" component={DetailVideoCommunity} />
          <Route path="/detailvideoone/:id" component={DetailVideoOne} />
          <Route path="/videoplay" component={VideoPlay} />

          <Route path="/*" component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default connect(null, { getProfile })(App);

// function App() {
//   return (
//     <BrowserRouter>
//         <Route path='/' exact component={LandingPage}/>
//         <Route path='/home' component={Home}/>
//         <Route path='/article' component={Article}/>
//         <Route path='/live' component={Live}/>
//         <Route path='/library' component={Library}/>
//         <Route path='/video'  component={Video}/>
//         <Route path='/pralogin' component={Pralogin}/>
//         <Route path='/login' component={login}/>
//         <Route path='/register' component={Register}/>
//         <Route path='/profile' component={Profile}/>
//         <Route path='/subscribenow' component={Subscribenow}/>
//         <Route path='/vouchercode' component={Vouchercode}/>
//         <Route path='/highlightpage' component={HighlightPage}/>
//         <Route path='/aboutus' component={AboutUs}/>
//         <Route path='/contactus' component={Contactus}/>
//         <Route path='/termcondition' component={TermCondition}/>
//         <Route path='/privacypolicy' component={Privacypolicy}/>
//         <Route path='/oneonpage' component={Oneonpage}/>
//         <Route path='/trendingpage' component={Trendingpage}/>
//         <Route path='/categorypage' component={Categorypage}/>
//         <Route path='/categorydetail' component={Categorydetail}/>
//         <Route path='/topcomppage' component={Topcomppage}/>
//         <Route path='/livedetail' component={Livedetail}/>
//         <Route path='/paynow' component={Paynow}/>
//         <Route path='/purchasehistory' component={PurchaseHistory}/>
//         <Route path='/paymentsuccess' component={Paymentsuccess}/>
//         <Route path='/favoritepage' component={FavoritePage}/>
//         <Route path='/detailarticle/:id' component={DetailArticle}/>
//         <Route path='/detaillibraryarticle/:id' component={DetailLibraryArticle}/>
//         <Route path='/detaillibraryvid/:id' component={DetailLibraryVideos}/>
//         <Route path='/detaills/:id' component={DetailLiveStreaming}/>
//         <Route path='/libraryarticle' component={LibraryArticle}/>
//         <Route path='/libraryphoto' component={LibraryPhoto}/>
//         <Route path='/videooneonone' component={VideoOneonone}/>
//         <Route path='/videocommunity' component={VideoCommunity}/>
//         <Route path='/ewallet' component={PaymentEwallet}/>
//         <Route path='/va' component={PaymentVA}/>
//         <Route path='/watchls' component={WatchLiveStreaming}/>

//     </BrowserRouter>
//   );
// }
