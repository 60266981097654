import React from 'react'
// import './oneonone.css'
// import { Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import { ONE, TWO, THREE } from '../../../assets/image/podcast'

class Recomendation extends React.Component {
    render() {
        return (
            <div className='container-fluid'>
                    <div className='row'>
                        <div className='d-flex flex-row justify-content-between  col-md pb-2'>
                            <h1 style={{fontSize:'1rem'}}>RECOMENDED</h1>
                            <h5 className='view-all'>View all {'>'} </h5>
                        </div>
                    </div>
                    <div className='d-flex overflow-auto'>
                        <div className='card-article col-md-4'>
                            <div className='card text-center bg-dark'>
                                <img src={ONE.default} alt='onepict' className='card-img img-fluid' />
                                <div className='card-body'>
                                    <p className='card-text text-left'>20 januari 2020</p>
                                    <h5 className=' card-title text-left'>The impact of the pandemic on athletes - One on One Eps.01</h5>
                                </div>
                            </div>
                        </div>
                        <div className='card-article col-md-4'>
                            <div className='card text-center bg-dark '>
                                <img src={TWO.default} alt='twopict' className='card-img img-fluid'/>
                                <div className='card-body'>
                                    <p className='card-text text-left'>20 januari 2020</p>
                                    <h5 className=' card-title text-left'>The impact of the pandemic on athletes - One on One Eps.01</h5>
                                </div>
                            </div>
                        </div>
                        <div className='card-article col-md-4'>
                            <div className='card text-center bg-dark'>
                                <img src={THREE.default} alt='threepict' className='card-img img-fluid' />
                                <div className='card-body'>
                                    <p className='card-text text-left'>20 januari 2020</p>
                                    <h5 className=' card-title text-left'>The impact of the pandemic on athletes - One on One Eps.01</h5>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}

export default Recomendation