import React from "react";
import axios from "axios";
import { Link, withRouter, NavLink, Redirect } from "react-router-dom";
import { API_URL } from "../../../config/common/baseurl";
import Article from "../../organism/Article";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarComp2 from "../../organism/NavbarComp2";
import { ONE } from "../../../assets/image/podcast";
import Cookise from "cookie-universal";

const cookie = new Cookise();

class Library extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataVideoContributor: [],
      videoHighlight: [],
    };
  }

  componentDidMount() {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const data = { role: "MPRO Contributor" };
    axios
      .post(`${API_URL}/video/contributor-role`, data, token)
      .then(async (res) => {
        // console.log("data contributor", res.data);
        let dataVideoContributor = [];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data) dataVideoContributor.push(res.data.data[i]);
          // console.log('data title', dataVideoContributor[i].title)
          // console.log('data contributor', dataVideoContributor[i].contributor.name)
          this.setState({
            dataVideoContributor: dataVideoContributor,
          });
        }
      })
      .catch((err) => console.log(err));
  }

  handlePlayVideo = (id) => {
    axios
      .get(`${API_URL}/video/play/${id}`, {
        headers: {
          access_token: cookie.get("access_token"),
        },
      })
      .then((res) => {
        // console.log("dapet data", res.data);
        cookie.set("titlevideo", res.data.data.title, { path: "/" });
        cookie.set("linkvideo", res.data.link, { path: "/" });
        document.location.href = "/videoplay/";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleGetID = (e) => {
    // console.log("ee", e.target);
    // console.log("idcowqwk", e.target.getAttribute("data-id"));
    const id = e.target.getAttribute("data-id");
    this.handlePlayVideo(id);
  };

  renderDataContributor = () => {
    return this.state.dataVideoContributor.map((val, i) => {
      //   console.log("karo koe", this.state.dataVideoContributor[i]._id);
      return (
        <div className="container-fluid">
          <div className="d-flex justify-content-center overflow-auto">
            <div className="col-md-4">
              <div
                // to={`/detaillibraryvid/${val._id}`}
                className="card text-center bg-dark"
              >
                <img
                  onClick={(e) => this.handleGetID(e)}
                  data-test="cok"
                  data-id={this.state.dataVideoContributor[i]._id}
                  src={val.thumbnail}
                  className="card-img img-fluid"
                  alt="onepict"
                />
                {/* <iframe src={this.state.dataVideoContributor} className='card-img img-fluid' /> */}
                {/* <video width="320" height="240" className='card-img img-fluid' controls autoPlay={true}>
                                    <source src={this.state.dataVideoContributor[i].video} type="video/mp4" />
                                </video> */}
                <div className="card-body">
                  <h5 className=" card-title text-left text-center" key={i}>
                    {this.state.dataVideoContributor[i].title}
                  </h5>
                  <div className="d-flex">
                    <img
                      className="rounded-circle img-fluid"
                      src={this.state.dataVideoContributor[i].contributor.photo}
                      style={{ maxHeight: "50px", maxWidth: "50px" }}
                      alt="contributor"
                    />
                    <div className="pl-2">
                      <h4
                        className="card-text text-white text-left"
                        style={{ fontSize: "12px", paddingLeft: "2px" }}
                        key={i}
                      >
                        {this.state.dataVideoContributor[i].contributor.name}
                      </h4>
                      <h4
                        className="card-text text-white text-left text-muted"
                        style={{ fontSize: "12px", paddingLeft: "2px" }}
                      >
                        {this.state.dataVideoContributor[i].dateString}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    const { location } = this.props;
    const { pathname } = location;
    const { dataVideoContributor, dataContributorName } = this.state;
    // console.log('uda dapet nih d state', dataVideoContributor)
    // console.log('uda dapet nih d state namana', dataContributorName)
    return (
      <div>
        <NavbarComp2 />
        <div className="container-fluid pt-2">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-4">
              <ul className="title-library d-flex justify-content-around list-unstyled">
                <li>
                  <NavLink to="/libraryarticle">
                    <span
                      style={{
                        color: `${
                          pathname === "/libraryarticle" ? "#FAA21B" : "#FFFFFF"
                        }`,
                      }}
                    >
                      Articles
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/libraryphoto">
                    <span
                      style={{
                        color: `${
                          pathname === "/libraryphoto" ? "#FAA21B" : "#FFFFFF"
                        }`,
                      }}
                    >
                      Pictures
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/library">
                    <span
                      style={{
                        color: `${
                          pathname === "/library" ? "#FAA21B" : "#FFFFFF"
                        }`,
                      }}
                    >
                      Videos
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex text-center text-white justify-content-center">
                <span className="title-content">
                  Check out the best videos about sports from our contributors.
                </span>
              </div>
            </div>
          </div>
        </div>
        {this.renderDataContributor()}
        <Footer />
        <FooterMobile />
      </div>
    );
  }
}

export default Library;
