import React from 'react'
import Axios from 'axios'
import { API_URL } from '../../../config/common/baseurl'
import { Link } from 'react-router-dom'
import '../../pages/App/App.css'

class Category extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataCategory: [],
            imgCategory: []
        }
    }

    componentDidMount() {
        Axios.get(`${API_URL}/sport`)
            .then(async (res) => {
                // console.log('sport category', res.data)
                let urls = []
                for (var i = 0; i < res.data.data.length; i++) {
                    if (res.data.data)
                        urls.push(res.data.data[i])
                    // console.log(res.data)
                    this.setState({ dataCategory : urls })
                }
            })
            .catch((err) => console.log(err))
    }

    renderImages = () => {
        return this.state.dataCategory.map((val, idx) => {
            // console.log('data category', val)
            return (
                <div className='oneon-card col-md-4'>
                    <Link to={`/categorypage/${val._id}`} className='card text-center bg-dark text-decoration-none'>
                        <img className="card-img img-fluid" src={val.image} alt="fightpict" key={idx} />
                    </Link>
                </div>
            )
        })
    }
    render() {
        // console.log('ini data d state', this.state.dataCategory)
        // console.log('ini imgCat d state', this.state.imgCategory)
        return (
            <div className='container-fluid py-5'>
                <div className='row'>
                    <div className='d-flex flex-row justify-content-between  col-md pb-2'>
                        <h1 style={{ fontSize: '1rem' }}>SPORT CATEGORY</h1>
                        <h5 className='view-all'>View all {'>'} </h5>
                    </div>
                </div>
                <div className='d-flex overflow-auto'>
                    {this.renderImages()}
                </div>
            </div>
        )
    }
}

export default Category