import React from 'react'
import Axios from 'axios'
import { API_URL } from '../../../config/common/baseurl'
import { Link } from 'react-router-dom'
import { ONE, TWO, THREE } from '../../../assets/image/podcast'
import { ICPREMIUM, ICLIVENOW } from '../../../assets/icon'
import '../../pages/App/App.css'
import Cookise from 'cookie-universal'

const cookie = new Cookise()

class LiveStreaming extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            dataLiveStreaming: [],
            dataDateString: [],
            dataDescription: []
        }
    }

    componentDidMount() {
        const token = {
            headers: { access_token: cookie.get('access_token') }
        }
        Axios.get(`${API_URL}/streaming/next`, null, token) 
            .then(async (res) => {
                // console.log('bedul', res.data.data)
                let dataLiveStreaming = []
                for (var i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i])
                        dataLiveStreaming.push(res.data.data[i])
                    this.setState({
                        dataLiveStreaming: dataLiveStreaming
                    })
                }
            }
            )
            .catch((err) => console.log(err))
    }

    renderLiveStreaming = () => {
        return this.state.dataLiveStreaming.map((val, i) => {
            return (
                <div className='oneon-card col-md-4'>
                    <Link to={`/detaills/${val._id}`} className='card text-center bg-dark text-decoration-none'>
                        <img src={val.thumbnail} alt='twopict' className='card-img img-fluid' key={i} />
                        <div className='icon-membership d-flex justify-content-between'>
                            <img src={ICPREMIUM.default} alt='icpremium' className='icon-premium' />
                            <img src={ICLIVENOW.default} alt='icpremium' className='icon-live' />
                        </div>
                        <div className='card-body'>
                            <p className='card-text text-left text-muted' key={i}>{val.dateString}</p>
                            <h5 className='card-title text-left' key={i}>{val.title}</h5>
                        </div>
                    </Link>
                </div>
            )
        })
    }
    render() {
        // console.log('data', this.state.dataLiveStreaming)
        return (
            <div className='container-fluid py-5'>
                <div className='row'>
                    <div className='d-flex flex-row justify-content-between  col-md pb-2'>
                        <h1 style={{ fontSize: '1rem' }}>LIVE STREAMING</h1>
                        <h5 className='view-all'>View all {'>'} </h5>
                    </div>
                </div>
                <div className='d-flex overflow-auto'>
                    {this.renderLiveStreaming()}
                </div>
            </div>
        )
    }
}

export default LiveStreaming