import React from 'react'
import axios from 'axios'
import Highlight from '../../organism/Highlight'
import NavbarBack from '../../organism/NavbarBack'
import Livecomp from '../../organism/Livecomp'
import { API_URL } from '../../../config/common/baseurl'
import { Link } from 'react-router-dom'
import { MUXL, FOOTBALLXL, UFCXL } from '../../../assets/image/match'
import { BOXINGHL2 } from '../../../assets/image/update'

class Categorydetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLiveStreaming: []
        }
    }
    componentDidMount = () => {
        // const cat = 'wbc'
        axios.post(`${API_URL}/streaming/competition`, {
            name: 'wbc'
        })
            .then(async (res) => {
                // console.log('data', res.data.data)
                this.setState({ dataLiveStreaming: res.data.data })
            }
            )
            .catch((err) => console.log(err))
    }

    renderLiveStreaming = () =>{
        return this.state.dataLiveStreaming.map((val,i)=>{
            return(
                <div className='container-fluid'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='card-article col-md-4'>
                            <Link className='card text-center bg-dark text-decoration-none'>
                                <img src={val.thumbnail} alt='onepict' className='card-img img-fluid' key={i} />
                                <div className='card-body'>
                                    {/* <h5 className='card-title text-left' key={i}>{val.title}</h5> */}
                                    {/* <div className='d-flex justify-content-between card-text text-muted'>
                                        <div className='d-flex flex-column'>
                                        <span className='text-muted'>{val.contributor.name}</span>
                                        <span className='text-muted'>{val.contributor.role}</span>
                                            </div>
                                        <p className='text-muted'>{val.dateString}</p>
                                    </div> */}
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <div className='big-container'>
                <NavbarBack />
                {this.renderLiveStreaming()}
                
            </div>
        )
    }
}

export default Categorydetail
