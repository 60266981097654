import React from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "../App/App.css";
import NavbarComp from "../../organism/NavbarComp";
import Footer from "../../organism/Footer";
import { Carousel } from "react-bootstrap";
import Button from "../../../components/atoms/button";
import { API_URL } from "../../../config/common/baseurl";
import logoCombat from "../../../assets/logo/sponsor/combatlogo.png";
import logoAdidas from "../../../assets/logo/sponsor/logoAdidasWhite.png";
import logoNike from "../../../assets/logo/sponsor/logoNike.png";
import logoKettler from "../../../assets/logo/sponsor/logoKettler.png";
import logoCola from "../../../assets/logo/sponsor/logoCola.png";
import logoRedBull from "../../../assets/logo/sponsor/logoRedbull.png";
import { ICPREMIUM, ICLIVENOW } from "../../../assets/icon";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      imgCarousel: [],
      dataLiveStreaming: [],
      dataDateString: [],
      dataDescription: [],
      imgCategory: [],
      idCategory: [],
    };
  }

  componentDidMount() {
    Axios.get(`${API_URL}/carousel`)
      .then(async (res) => {
        // console.log('res karosel', res)
        let img = [];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].picture) img.push(res.data.data[i].picture);
        }
        this.setState({ imgCarousel: img });
      })
      .catch((err) => console.log(err));

    Axios.get(`${API_URL}/streaming/next`)
      .then(async (res) => {
        // console.log('res data', res.data.data)
        let dataLiveStreaming = [];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i]) dataLiveStreaming.push(res.data.data[i]);
          this.setState({
            dataLiveStreaming: dataLiveStreaming,
          });
        }
      })
      .catch((err) => console.log(err));

    Axios.get(`${API_URL}/sport`)
      .then(async (res) => {
        let urls = [];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data) urls.push(res.data.data[i]);
          this.setState({ imgCategory: urls });
        }
      })
      .catch((err) => console.log(err));
  }

  handleMoreDetails = () => {
    window.scrollBy(0, 600);
  };

  renderCarousel = () => {
    return this.state.imgCarousel.map((val, idx) => {
      return (
        <Carousel.Item>
          <img
            className="caraousel-item"
            src={val}
            alt="First slide"
            key={idx}
          />
        </Carousel.Item>
      );
    });
  };

  renderLiveStreaming = () => {
    return this.state.dataLiveStreaming.map((val, i) => {
      return (
        <div className="oneon-card col-md-4">
          <Link
            to="/login"
            className="card text-center bg-dark text-decoration-none"
          >
            <img
              src={val.thumbnail}
              alt="twopict"
              className="card-img img-fluid"
              key={i}
            />
            <div className="icon-membership d-flex justify-content-between">
              <img
                src={ICPREMIUM.default}
                alt="icpremium"
                className="icon-premium"
              />
              <img
                src={ICLIVENOW.default}
                alt="icpremium"
                className="icon-live"
              />
            </div>
            <div className="card-body">
              <p className="card-text text-left text-muted" key={i}>
                {val.dateString}
              </p>
              <h5 className="card-title text-left" key={i}>
                {val.title}
              </h5>
            </div>
          </Link>
        </div>
      );
    });
  };

  renderImages = () => {
    return this.state.imgCategory.map((val, idx) => {
      // console.log('value img sport', val)
      return (
        <div className="oneon-card col-md-4">
          <Link
            to="/login"
            className="card text-center bg-dark text-decoration-none"
          >
            <img
              className="card-img img-fluid"
              src={val.image}
              alt="fightpict"
              key={idx}
            />
          </Link>
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <NavbarComp />
        <Carousel fade>{this.renderCarousel()}</Carousel>

        <section className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-5 text-center py-5 px-5">
              <h1 className="title"> INDONESIA'S BEST SPORTS APP</h1>
              <p>
                {" "}
                Enjoy sports broadcast anywhere anytime easily and exclusively
                with MPRO.
              </p>
            </div>
          </div>
        </section>
        <div className="container-fluid py-5">
          <div className="row">
            <div className="d-flex flex-row justify-content-between  col-md pb-2">
              <h1 style={{ fontSize: "1rem" }}>LIVE STREAMING</h1>
              <Link to="/login">
                <h5 className="view-all">View all {">"} </h5>
              </Link>
            </div>
          </div>
          <div className="d-flex overflow-auto">
            {this.renderLiveStreaming()}
          </div>
        </div>
        <div className="container-fluid py-5">
          <div className="row">
            <div className="d-flex flex-row justify-content-between  col-md pb-2">
              <h1 style={{ fontSize: "1rem" }}>SPORT CATEGORY</h1>
              <Link to="/login">
                <h5 className="view-all">View all {">"} </h5>
              </Link>
            </div>
          </div>
          <div className="d-flex overflow-auto">{this.renderImages()}</div>
        </div>
        {/* <section className="register-benefit">
          <div className="container">
            <div className="row">
              <div className="col d-flex justify-content-center text-center">
                <ul className="main-benefit">
                  <li className="d-flex">
                    <i class="fas fa-check my pr-2 pt-2"></i>
                    <p>FREE access all regular contents</p>
                  </li>
                  <li className="d-flex">
                    <i class="fas fa-check my pr-2 pt-2"></i>
                    <p>Only $3.00 or less for an exclusive live streaming</p>
                  </li>
                  <li className="d-flex">
                    <i class="fas fa-check my pr-2 pt-2"></i>
                    <p>Exclusive boxing and cycling category</p>
                  </li>
                  <li className="d-flex">
                    <i class="fas fa-check my pr-2 pt-2"></i>
                    <p>The MPRO app is available in English language Only</p>
                  </li>
                  <Link className="link text-decoration-none" to="./login">
                    <Button />
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </section> */}
        <section className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-5 text-center py-5 px-5">
              <h5 className="pb-4 text-bold">Our Partner </h5>
              <img
                src={logoCombat}
                style={{ maxWidth: "50px" }}
                className="mx-3"
                alt="logo-combat"
              />
              <img
                src={logoAdidas}
                className="mx-3"
                style={{ maxWidth: "50px" }}
                alt="logo-combat"
              />
              <img
                src={logoNike}
                className="mx-3"
                style={{ maxWidth: "50px" }}
                alt="logo-combat"
              />
              <img
                src={logoKettler}
                className="mx-3"
                style={{ maxWidth: "50px" }}
                alt="logo-combat"
              />
              <img
                src={logoRedBull}
                className="mx-3"
                style={{ maxWidth: "50px" }}
                alt="logo-combat"
              />
              <img
                src={logoCola}
                className="mx-3"
                style={{ maxWidth: "50px" }}
                alt="logo-combat"
              />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default LandingPage;
