import React from 'react'
import { Container } from 'react-bootstrap'
import Footer from '../../organism/Footer'
import FooterMobile from '../../organism/FooterMobile'
import NavbarComp from '../../organism/NavbarComp'
import Button from '../../../components/atoms/button'

class Subscribenow extends React.Component {
    render() {
        return (
            <div >
                <NavbarComp />
                <Container fluid style={{ minHeight: '80vh', padding: '50px 100px' }}>
                    {/* <div className='row justify-content-md-center' >
                        <div className='col-md-2 bg-primary'>
                            <h5 className='text-white bg-dark'>
                                Subscribe Now
                            </h5>
                            <h5>
                                Join us for the best sports experience
                            </h5>
                        </div>
                    </div> */}
                    <div className='row px-2 py-4 justify-content-center align-items-center'>
                        <h5 className='text-white text-center'>
                            Subscribe Now
                            </h5>
                        <p className='text-center text-white'>
                            Join us for the best sports experience
                            </p>
                        <div className="col-md-3">
                            <div className="text-center py-5 px-5">
                                <div className="card-body">
                                    <h4 className="card-title">BASIC</h4>
                                    <p className="card-text">1 device</p>
                                    <p className="card-text">Quality 480p</p>
                                    <h5 className="card-title">Rp. 49.000,-</h5>
                                    <p className="card-text">/month</p>
                                    <Button />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="text-center py-5 px-5">
                                <div className="card-body">
                                    <h4 className="card-title">REGULAR</h4>
                                    <p className="card-text">2 device</p>
                                    <p className="card-text">Quality 480p & 720p</p>
                                    <h5 className="card-title">Rp. 99.000,-</h5>
                                    <p className="card-text">/month</p>
                                    <Button />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="text-center py-5 px-5">
                                <div className="card-body">
                                    <h4 className="card-title">PREMIUM</h4>
                                    <p className="card-text">4 device</p>
                                    <p className="card-text">Quality HD 1080p</p>
                                    <h5 className="card-title">Rp. 149.000,-</h5>
                                    <p className="card-text">/month</p>
                                    <Button />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <Footer />
                <FooterMobile />
            </div>
        )
    }
}

export default Subscribenow