import React from 'react'
import { LOGOIMGXL } from '../../../assets/logo/index'
import { Link } from 'react-router-dom'
import { LOOPXL, USERXL } from '../../../assets/icon'
import '../../pages/App/App.css'
import { connect } from 'react-redux'
import { API_URL } from '../../../config/common/baseurl'
import axios from 'axios';
import Cookies from 'cookie-universal'

const cookie = new Cookies()

class NavbarComp2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: true,
            nameNavbar: ''
        }
        this.handleclick = this.handleClick.bind(this)
    }
    handleClick = () => {
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn
        })

        )
    }
    handleLoopIcon = (e) => {
        e.preventdefault()
        // console.log('you clicked me')
    }
    getProfile = () => {
        const headers = {
            access_token: cookie.get('access_token')
        };
        axios.get(`${API_URL}/member/detail`, { headers })
            .then((res) => {
                // console.log('data ygada d APP navbar', res.data.data)
                this.setState( { nameNavbar : res.data.data.username})
                // this.props.getProfile(res.data.data)
            }
            )
            .catch((err) => console.log(err))
    }
    componentDidMount() {
        this.getProfile()
    }
    render() {
        // console.log('reduz', this.props.username)
        return (
            <div>
                <nav className="navbar navbar-expand-lg bg-dark">
                    <div className='col'>
                        <div className='row'>
                            <div className="d-flex justify-content-between ms-3">
                                <Link to='/home'>
                                    <img src={LOGOIMGXL.default}
                                        alt="logompro" />
                                </Link>
                                <div className="collapse navbar-collapse ms-5 " id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                                        <Link style={{ textDecoration: 'none' }} to='/home' >
                                            <li className="nav-item mx-3">
                                                <div onClick={this.handleChangetext} className="nav-link text-white" aria-current="page">Home</div>
                                            </li>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} to='/article' >
                                            <li className="nav-item mx-3">
                                                <div className="nav-link text-white" aria-current="page">Article</div>
                                            </li>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} to='/live'>
                                            <li className="nav-item mx-3">
                                                <div className="nav-link text-white" aria-current="page" >Calendar</div>
                                            </li>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} to='/library'>
                                            <li className="nav-item mx-3">
                                                <div className="nav-link text-white" aria-current="page">Library</div>
                                            </li>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} to='/video'>
                                            <li className="nav-item mx-3">
                                                <div className="nav-link text-white" href="#">Video</div>
                                            </li>
                                        </Link>
                                    </ul>
                                </div>
                                <div className='col-xs-1 text-white mt-1 d-flex'>
                                    {/* <div className='pr-2' >
                                        {this.state.isToggleOn ? 
                                        <img onClick={this.handleclick} src={LOOPXL.default}
                                            alt="ikon-search" /> 
                                            : 
                                            
                                            <div class="input-group rounded">
                                            <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search"
                                                aria-describedby="search-addon" />
                                            <span class="input-group-text border-0" id="search-addon">
                                                <i onClick={this.handleclick}  class="fas fa-search"></i>
                                            </span>
                                        </div>}

                                    </div> */}
                                    <Link className='pl-3' style={{ textDecoration: 'none' }} to='/pralogin'>
                                        <p>
                                            hi, {this.state.nameNavbar}
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        username: state.user.username
    }
}

export default connect(mapStateToProps)(NavbarComp2)
