import React from "react";
import Axios from "axios";
import { API_URL } from "../../../config/common/baseurl";
import { Link, Redirect } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import NavbarBack from "../../organism/NavbarBack";
import FooterMobile from "../../organism/FooterMobile";
import Footer from "../../organism/Footer";
import firebase from "../../../config/firebase";
import "../App/App.css";
import Cookies from "cookie-universal";
import { connect } from "react-redux";
import { register } from "../../../config/action";

const cookies = new Cookies();

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      email: "",
      password: "",
      username: "",
      passwordConfirm: "",
      toHome: false,
      checkValidErr: [false, ""],
      RegisterError: [false, ""],
      emailValidErr: [false, ""],
      userValidErr: [false, ""],
      addressValidErr: [false, ""],
      phoneNumberValidErr: [false, ""],
      passValidErr: [false, ""],
      checked: false,
    };
  }
  handleChangeText = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleRegister = () => {
    const { userValidErr, emailValidErr, passValidErr } = this.state;
    let username = this.refs.username.value;
    let address = this.refs.address.value;
    let email = this.refs.email.value;
    let password = this.refs.password.value;
    let confirmPassword = this.refs.confirmPassword.value;
    let phoneNumber = this.refs.phoneNumber.value;
    let birth = this.refs.birth.value;

    // console.log(username, email, password, confirmPassword, phoneNumber, birth, address)

    if (
      !username ||
      !email ||
      !password ||
      !confirmPassword ||
      !phoneNumber ||
      !birth
    )
      return alert("please input all form");

    if (userValidErr[0] || emailValidErr[0] || passValidErr[0])
      return alert("Make sure there is no error in validation");

    if (!this.state.checked) return alert("please guarentee your information");

    Axios.post(`${API_URL}/member/register`, {
      username: username,
      address: address,
      email: email,
      phone: phoneNumber,
      birth: birth,
      password: password,
      passwordConfirm: confirmPassword,
    })
      .then((res) => {
        // console.log('data register', res.data.data)
        this.props.register(res.data.data);
        alert("akunmu sukses terdaftar");
        cookies.set("access_token", res.data.access_token, { path: "/" });
        this.setState({ toHome: true });
      })
      .catch((err) => {
        // console.log(err)
        alert(err.response.data.message);
      });
  };

  userValid = (e) => {
    let username = e.target.value; //target=>properti dari event
    // console.log(username)
    let symb = /[!@#$%^&*;]/;

    if (symb.test(username) || username.length < 6)
      return this.setState({
        userValidErr: [true, "*cant include symb and min 6 char"],
      });

    this.setState({ userValidErr: [false, ""] });
  };
  emailValid = (e) => {
    let email = e.target.value;
    // console.log(email)

    let regex =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(email))
      return this.setState({ emailValidErr: [true, "*Email not valid"] });

    this.setState({ emailValidErr: [false, ""] });
  };

  addressValid = (e) => {
    let address = e.target.value;

    if (address.length > 100)
      return this.setState({ addressValidErr: [true, "*address max 25 char"] });

    this.setState({ addressValidErr: [false, ""] });
  };

  phoneValid = (e) => {
    let phonenumber = e.target.value;

    let regex =
      /(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/;

    if (regex.test(phonenumber))
      return this.setState({
        phoneNumberValidErr: [false, "*phone number not valid "],
      });
  };
  passValid = (e) => {
    let password = e.target.value;
    // console.log(password)
    // let symb = /[!@#$%^&*;]/
    let numb = /[0-9]/;
    // let upper = /[A-Z]/

    if (!numb.test(password) || password.length < 6)
      return this.setState({
        passValidErr: [true, "*must include number and min 6 char"],
      });

    this.setState({ passValidErr: [false, ""] });
  };

  checkValidErr = (e) => {
    let checked = e.target.value;
    if (checked) this.setState({ checked: true });
  };

  render() {
    const {
      visible,
      toHome,
      emailValidErr,
      userValidErr,
      addressValidErr,
      phoneNumberValidErr,
      passValidErr,
    } = this.state;
    if (toHome) return <Redirect to="/home" />;
    return (
      <div>
        <NavbarBack />
        <Container
          fluid
          style={{ minHeight: "80vh", backgroundColor: "black" }}
        >
          <Row>
            <Col></Col>
            <Col md={3}>
              <Form className="mt-5">
                <Form.Label className="pt-3">
                  <h6>User Name</h6>
                </Form.Label>
                <Form.Control
                  ref="username"
                  id="username"
                  onChange={(e) => this.userValid(e)}
                  type="text"
                  placeholder="input your username"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                />
                <Form.Label className="pt-3">
                  <Form.Text
                    className="mb-3"
                    style={{
                      textAlign: "left",
                      color: "red",
                      fontSize: "10px",
                    }}
                  >
                    {userValidErr[1]}
                  </Form.Text>
                  <h6>Email</h6>
                </Form.Label>
                <Form.Control
                  ref="email"
                  id="email"
                  onChange={(e) => this.emailValid(e)}
                  type="text"
                  placeholder="email@email.com"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                />
                <Form.Text
                  className="mb-3"
                  style={{ textAlign: "left", color: "red", fontSize: "10px" }}
                >
                  {emailValidErr[1]}
                </Form.Text>
                <Form.Label className="pt-3">
                  <h6>Address</h6>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  ref="address"
                  id="address"
                  onChange={(e) => this.addressValid(e)}
                  type="text"
                  placeholder="input your address"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                />
                <Form.Text
                  className="mb-3"
                  style={{ textAlign: "left", color: "red", fontSize: "10px" }}
                >
                  {addressValidErr[1]}
                </Form.Text>
                <Form.Label className="pt-3">
                  <h6>Date of Birth</h6>
                </Form.Label>
                <Form.Control
                  // ref='birth'
                  ref="birth"
                  size="lg"
                  type="date"
                  data-date-format="mm/dd/yy"
                  placeholder="Pilih tanggal lahir kamu"
                  style={{
                    backgroundColor: "black",
                    borderRadius: "10px",
                    placeholder: "white",
                  }}
                />
                <Form.Label className="pt-3">
                  <h6>Phone Number</h6>
                </Form.Label>
                <Form.Control
                  ref="phoneNumber"
                  id="phone-number"
                  onChange={(e) => this.phoneValid(e)}
                  type="number"
                  placeholder="input your phone number"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                />
                {/* <Form.Text
                  className="mb-3"
                  style={{ textAlign: "left", color: "red", fontSize: "10px" }}
                >
                  {phoneNumberValidErr[1]}
                </Form.Text> */}
                <Form.Label className="pt-3">
                  <h6>Password</h6>
                </Form.Label>
                <Form.Control
                  ref="password"
                  id="password"
                  onChange={this.handleChangeText}
                  type={visible ? "text" : "password"}
                  placeholder="minimum 6 character"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                />
                <Row>
                  <Col
                    onClick={() => this.setState({ visible: !visible })}
                    className="inputicon"
                    md={1}
                    style={{
                      color: "white",
                      cursor: "pointer",
                      marginLeft: "auto",
                      paddingBottom: "2px",
                    }}
                  >
                    {visible ? (
                      <i className="fas fa-eye-slash"></i>
                    ) : (
                      <i className="fas fa-eye"></i>
                    )}
                  </Col>
                </Row>
                <Form.Text
                  className="mb-3"
                  style={{ textAlign: "left", color: "red", fontSize: "10px" }}
                >
                  {passValidErr[1]}
                </Form.Text>
                <Form.Label className="pt-3">
                  <h6>Confirm Password</h6>
                </Form.Label>
                <Form.Control
                  ref="confirmPassword"
                  id="passwordConfirm"
                  onChange={this.handleChangeText}
                  type={visible ? "text" : "password"}
                  placeholder="minimum 6 character"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                />
                <Row>
                  <Col
                    onClick={() => this.setState({ visible: !visible })}
                    className="inputicon"
                    md={1}
                    style={{
                      color: "white",
                      cursor: "pointer",
                      marginLeft: "auto",
                      paddingBottom: "2px",
                    }}
                  >
                    {visible ? (
                      <i className="fas fa-eye-slash"></i>
                    ) : (
                      <i className="fas fa-eye"></i>
                    )}
                  </Col>
                </Row>
                <Form.Group className="d-flex py-3">
                  <Form.Check
                    style={{ paddingTop: "2px" }}
                    name="checkbox"
                    value="check"
                    type="checkbox"
                    onChange={(e) => this.checkValidErr(e)}
                  />
                  <p className="text-white" style={{ opacity: "0.4" }}>
                    I guarentee that the above information is correct and in
                    accordance with my identity.
                  </p>
                  {/* <Form.Check
                                        required
                                        label="I guarantee that the above informarion is correct and in accordance with my identity"
                                        feedback="You must agree before submitting."
                                        style={{ fontSize:'12px',color:'white' }}
                                    /> */}
                </Form.Group>
                <Button
                  onClick={this.handleRegister}
                  style={{
                    backgroundColor: "#faa21b",
                    border: "none",
                    fontFamily: "microextend",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  block
                >
                  REGISTER
                </Button>
                <div className="d-flex justify-content-center pb-5 mt-3">
                  <p>Have an account?</p>
                  <Link style={{ textDecoration: "none" }} to="/login">
                    <h6 className="pt-1 pl-3">Login</h6>
                  </Link>
                </div>
              </Form>
            </Col>
            <Col></Col>
          </Row>
        </Container>
        <Footer />
        {/* <FooterMobile /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.user.username,
  };
};

export default connect(mapStateToProps, { register })(Register);
