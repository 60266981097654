import React, { Component } from 'react'
import '../../pages/App/App.css'

export default class Language extends Component {
    render() {
        return (
            <div class='language-body'>
                <div class='container-fluid'>
                    <div class='row'>
                        <div class='col d-flex justify-content-center bg-primary text-white'>
                                languange option 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
