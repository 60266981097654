import React, { Component } from 'react'
import NavbarBack from '../../organism/NavbarBack'
import { BOXINGCAT } from '../../../assets/image/update'
import { WBCXL } from '../../../assets/logo/topcompetition'
import { Link, Redirect } from 'react-router-dom'
import { API_URL } from '../../../config/common/baseurl'
import '../App/App.css'
import Cookies from 'cookie-universal'
import axios from 'axios'
const cookie = new Cookies()

export class Categorypage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSportCategory: [],
            dataSport: []
        }
    }
    componentDidMount = () => {
        axios.get(`${API_URL}/sport`)
            .then(async (res) => {
                // console.log('data', res.data.data)
                const b = res.data.data.find(x => x._id === this.props.match.params.id);
                // console.log('b =>', b);
                // console.log('competition', b.competition)
                this.setState({ dataSport: b })
                this.setState({ dataSportCategory: b.competition })
                // for (var i = 0; i < b.competition.length; i++) {
                //     // console.log('ava', b.competition[i].logo)
                //     this.setState({ dataLogo: b.competition[i].logo })
                // }
            }
            )
            .catch((err) => console.log(err))
    }

    renderCompetition = () => {
        return this.state.dataSportCategory.map((val, i) => {
            // console.log('logo', val.competition)
            return (

                <Link to='/categorydetail' className='m-3 bg-dark rounded'>
                    <img fluid
                        src={val.logo}
                        alt='detail-live'
                        width='75'
                        height='75'
                        key={i}
                    />
                </Link>

            )
        })
    }

    render() {
        // console.log('tes state', this.state.dataSportCategory)
        const { dataSport } = this.state
        if (cookie.get('access_token') == null || cookie.get('access_token').length < 200 || cookie.get('access_token').length > 250) {
            return <Redirect to='/login' />
        }
        return (
            <div className='big-container-catpage'>
                <NavbarBack />
                <div className='container-fluid'>
                    <div className='row justify-content-md-center'>
                        <div className='d-flex justify-content-center col-md-4'>
                            <img fluid
                                src={dataSport.image}
                                alt='detail-live' />
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row justify-content-md-center'>
                        <div className='d-flex col-md-4'>
                            {this.renderCompetition()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Categorypage
