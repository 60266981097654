import React from "react";
import Axios from "axios";
import { Link, Redirect } from "react-router-dom";
import "../App/App.css";
import NavbarComp2 from "../../organism/NavbarComp2";
import Footer from "../../organism/Footer";
import { Carousel } from "react-bootstrap";
import { MATCH1XL, MOTOGPXL } from "../../../assets/image/caraousel";
import FooterMobile from "../../organism/FooterMobile";
import Button from "../../../components/atoms/button";
import Highlight from "../../../container/organism/Highlight";
import Category from "../../organism/Category";
import Oneonone from "../../organism/Oneonone";
import Livecomp from "../../organism/Livecomp";
import DontMiss from "../../organism/DontMiss";
import TopArticle from "../../organism/TopArticle";
import Recomendation from "../../organism/Recomendation/Index";
import LiveStreaming from "../../organism/LiveStreaming";
import { API_URL } from "../../../config/common/baseurl";
import Cookies from "cookie-universal";
import { connect } from "react-redux";
import logoCombat from "../../../assets/logo/sponsor/combatlogo.png";
import logoAdidas from "../../../assets/logo/sponsor/logoAdidasWhite.png";
import logoNike from "../../../assets/logo/sponsor/logoNike.png";
import logoKettler from "../../../assets/logo/sponsor/logoKettler.png";
import logoCola from "../../../assets/logo/sponsor/logoCola.png";
import logoRedBull from "../../../assets/logo/sponsor/logoRedbull.png";

const cookies = new Cookies();

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      imgCarousel: [],
    };
  }

  componentDidMount() {
    const token = {
      headers: { access_token: cookies.get("access_token") },
    };
    Axios.get(`${API_URL}/carousel`, token)
      .then(async (res) => {
        let img = [];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].picture) img.push(res.data.data[i].picture);
        }
        this.setState({ imgCarousel: img });
      })
      .catch((err) => console.log(err));
  }
  handleMoreDetails = () => {
    window.scrollBy(0, 600);
  };
  renderCarousel = () => {
    return this.state.imgCarousel.map((val, idx) => {
      return (
        <Carousel.Item>
          <img
            className="caraousel-item"
            src={val}
            alt="First slide"
            key={idx}
          />
        </Carousel.Item>
      );
    });
  };
  render() {
    if (
      cookies.get("access_token") == null ||
      cookies.get("access_token").length < 200 ||
      cookies.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <NavbarComp2 />
        <Carousel fade>{this.renderCarousel()}</Carousel>
        <section className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-5 text-center py-5 px-5">
              <h1 className="title"> INDONESIA'S BEST SPORTS APP</h1>
              <p>
                {" "}
                Enjoy sports broadcast anywhere anytime easily and exclusively
                with MPRO.
              </p>
            </div>
          </div>
        </section>
        <LiveStreaming />
        <Category />
        <section className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-5 text-center py-5 px-5">
              <h5 className="pb-4 text-bold">Our Partner </h5>
              <img
                src={logoCombat}
                style={{ maxWidth: "50px" }}
                className="mx-3"
                alt="logo-combat"
              />
              <img
                src={logoAdidas}
                className="mx-3"
                style={{ maxWidth: "50px" }}
                alt="logo-combat"
              />
              <img
                src={logoNike}
                className="mx-3"
                style={{ maxWidth: "50px" }}
                alt="logo-combat"
              />
              <img
                src={logoKettler}
                className="mx-3"
                style={{ maxWidth: "50px" }}
                alt="logo-combat"
              />
              <img
                src={logoRedBull}
                className="mx-3"
                style={{ maxWidth: "50px" }}
                alt="logo-combat"
              />
              <img
                src={logoCola}
                className="mx-3"
                style={{ maxWidth: "50px" }}
                alt="logo-combat"
              />
            </div>
          </div>
        </section>
        <Footer />
        <FooterMobile />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    username: state.user.username,
  };
};

export default connect(mapStateToProps)(Home);
