const INITIAL_STATE = {
    username: '',
    email: '',
    accesstoken: ''
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOG_IN':
            return {
                ...state,
                username: action.payload.username,
                email: action.payload.email,
                accesstoken: action.payload.accesstoken
            }
        case 'USER_PROFILE':
            return {
                ...state,
                name: action.payload.username,
                email: action.payload.email,
                password: action.payload.password,
                // expired: action.payload.expired
            }
        case 'REGISTER':
            return {
                ...state,
                username: action.payload.username,
                email: action.payload.email,
                password: action.payload.password
                // expired: action.payload.expired
            }
        case 'LOG_OUT':
            return INITIAL_STATE
        default:
            return state
    }
}



export default userReducer