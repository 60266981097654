import React from 'react'
import { Container, Row, Col, Form, Button, Dropdown } from 'react-bootstrap'
import NavbarBack from '../../organism/NavbarBack'
import FooterMobile from '../../organism/FooterMobile'
import Footer from '../../organism/Footer'
import '../App/App.css'
import axios from 'axios'
import { API_URL } from '../../../config/common/baseurl'
import { Redirect } from 'react-router-dom'
import Cookise from 'cookie-universal'

const cookie = new Cookise()


class Profile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newPicture: '',
            newEmail: '',
            newName: '',
            newPhone: '',
            newBirth: '',
            toHome: false
        }
    }

    newPicture = (e) => {
        this.setState({
            newPicture: e.target.value
        })
    }

    newEmail = (e) => {
        this.setState({
            newEmail: e.target.value
        })
    }
    newName = (e) => {
        this.setState({
            newName: e.target.value
        })
    }
    newPhone = (e) => {
        this.setState({
            newPhone: e.target.value
        })
    }
    newBirth = (e) => {
        this.setState({
            newBirth: e.target.value
        })
    }

    handleEditProfile = () => {
        let newPicture = this.state.newPicture
        let newEmail = this.state.newEmail
        let newName = this.state.newName
        let newPhone = this.state.newPhone

        // console.log(newPicture, newEmail, newName, newPhone)

        // if(!newEmail || !newName || !newPhone ) return alert('please input all form')

        const token = {
            headers: { access_token: cookie.get('access_token'), 'Content-Type': 'multipart/form-data' }
        }
        var formData = new FormData();
        var imagefile = document.querySelector('#input-image');
        formData.append("picture", imagefile.files[0]);
        formData.append('email', newEmail) 
        formData.append('username', newName) 
        formData.append('phone', newPhone) 
        // formData.append('email', newEmail) 
        axios.post(`${API_URL}/member/edit`, formData, token)
            .then((res) => {
                // console.log('edit', res)
                alert('your profile sucesfully edited')
                this.setState({ toHome: true })
            })
            .catch((err) => {
                // console.log(err)
            })
    }

    render() {
        if (cookie.get('access_token') == null || cookie.get('access_token').length < 200 || cookie.get('access_token').length > 250) {
            return <Redirect to='/login' />
        }
        const { toHome } = this.state
        if (toHome) return <Redirect to='/pralogin' />
        return (
            <div>
                <NavbarBack />
                <Container fluid style={{ minHeight: '80vh', backgroundColor: 'black' }}>
                    <Row>
                        <Col></Col>
                        <Col md={3}>
                            <Form className='mt-5'>
                                <Form.Label className='pt-3'>
                                    <h6>
                                        Add Photo Profile
                                    </h6>
                                </Form.Label>
                                <Form.Control
                                    method="post" action="post" enctype="multipart/form-data"
                                    accept="image/png, image/jpg"
                                    id='input-image'
                                    onChange={(e) => this.newPicture(e)}
                                    type="file"
                                    placeholder="email@email.com"
                                    style={{ backgroundColor: 'black', padding: '20px', borderRadius: '10px', color: 'white' }} />
                                <Form.Label className='pt-3'>
                                    <h6>
                                        Email
                                    </h6>
                                </Form.Label>
                                <Form.Control
                                    id='email'
                                    onChange={(e) => this.newEmail(e)}
                                    type="text"
                                    placeholder="email@email.com"
                                    style={{ backgroundColor: 'black', padding: '20px', borderRadius: '10px', color: 'white' }} />
                                <Form.Label className='pt-3'>
                                    <h6>
                                        Name
                                    </h6>
                                </Form.Label>
                                <Form.Control
                                    id='email'
                                    onChange={(e) => this.newName(e)}
                                    type="text"
                                    placeholder="name"
                                    style={{ backgroundColor: 'black', padding: '20px', borderRadius: '10px', color: 'white' }} />
                                <Form.Label className='pt-3'>
                                    <h6>
                                        Phone Number
                                    </h6>
                                </Form.Label>
                                <Form.Control
                                    id='password'
                                    onChange={(e) => this.newPhone(e)}
                                    type="number"
                                    placeholder="+62 ..."
                                    style={{ backgroundColor: 'black', padding: '20px', borderRadius: '10px', color: 'white' }} />
                                {/* <Row>
                                    <Col onClick={() => this.setState({ visible: !visible })} className='inputicon' md={1} style={{ cursor: 'pointer', marginLeft:'auto', paddingBottom:'2px' }}>
                                        {visible ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                                    </Col>
                                </Row> */}
                                {/* <Form.Label className='pt-3'>
                                    <h6>
                                        Birth Date
                                    </h6>
                                </Form.Label>
                                <Form.Row className='justify-content-around mb-4'>
                                    <Form.Control
                                    onChange={(e) => this.newBirth(e)}
                                    ref='birth'
                                    size="lg"
                                    ref='birth'
                                    type="date"
                                    placeholder="Pilih tanggal lahir kamu"
                                    style={{  backgroundColor: 'black', borderRadius: '10px', placeholder: 'white' }}
                                />
                                </Form.Row> */}
                                {/* <Form.Group className='py-3'>
                                    <Form.Check
                                        required
                                        label="Im 18 years old"
                                        feedback="You must agree before submitting."
                                        style={{ backgroundColor:'black', fontSize:'12px',color:'white' }}
                                    />
                                </Form.Group> */}
                                <Button
                                    className='mt-3'
                                    onClick={this.handleEditProfile}
                                    style={{ backgroundColor: '#faa21b', border: 'none', fontFamily: 'microextend', borderRadius: '10px', cursor: 'pointer' }}
                                    block>
                                    SAVE
                                </Button>
                            </Form>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
                <Footer />
                <FooterMobile />
            </div>
        )
    }
}

export default Profile