export const LOOPSM = require('./loop-sm.png')
export const LOOPXL = require('./loop-xl.png')
export const USERSM = require('./username-sm.png')
export const USERXL = require('./username-xl.png')
export const IGXL = require('./ig-xl.png')
export const IGSM = require('./ig.png')
export const FBXL = require('./fb-xl.png')
export const FBSM = require('./fb.png')
export const TTXL = require('./twitter-xl.png')
export const TTSM = require('./twitter.png')
export const UTUBEXL = require('./youtube-xl.png')
export const UTUBESM = require('./youtube.png')
export const PROFILESM = require('./profile_icon.png')
export const PROFILEXL = require('./profile_icon_xl.png')
export const FAVORITEXL = require('./favorite_menuXL.png')
export const FAVORITE = require('./favorite_menu.png')
export const LANGUAGEXL = require('./languageXL.png')
export const VOUCHERXL = require('./voucherXL.png')
export const LOGOUTXL = require('./ic_logout.png')
export const ICSHARE = require('./ic_share.png')
export const ICSHAREXL = require('./ic_share@2x.png')
export const ICPREMIUM = require('./ic_premium.png')
export const ICLIVENOW = require('./ic_livenow.png')
export const ICARTICLEON = require('./ic_article_on.png')


