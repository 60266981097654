import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarBack from "../../organism/NavbarBack";

class TermCondition extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "black",
          minHeight: "100vh",
          padding: "20px",
        }}
      >
        <NavbarBack />
        <Container>
          <Row>
            <Col></Col>
            <Col md={8}>
              <h1 style={{ fontSize: "16px" }} className="py-3">
                TERM AND CONDITION
              </h1>
            </Col>
            <Col></Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col></Col>
            <Col md={8}>
              <p style={{ fontFamily: "notoSansReg", fontSize: "12px" }}>
                <p>1. General Information and Web Page Ownership</p>
                <p className="text-muted">
                  MPRO is the exclusive owner of Web Pages provided to internet
                  users for the purpose of providing information about all the
                  services it providers. MPRO thereinafter referred to as Web
                  Pages is an internet portal that airns to offer information
                  about service and contents and about television programs
                  broadcast an MPRO channels, as well as access to news and
                  other content that have or are not made specifically for the
                  network. MPRO is a trade name of MPRO.
                </p>
              </p>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col md={8}>
              <p style={{ fontFamily: "notoSansReg", fontSize: "12px" }}>
                <p>2. Scope of Application</p>
                <p className="text-muted ">
                  2.1 The current General Terms govern access to content and all
                  services offered by MPRO through its Web Pages, as well as
                  their use by Users. MPRO reserves the right to change the
                  presentation, configuration, and content of the website and
                  services, as well as the conditions required for their access
                  and or use. Access to and use the content and services after
                  your modification or change takes effect implies acceptance of
                  them.
                </p>
              </p>
              <p
                className="text-muted"
                style={{ fontFamily: "notoSansReg", fontSize: "12px" }}
              >
                2.2 Regardless of access of certain content and use certain
                services, they may be subject to certain conditions, which
                according to the case will replace, complement and/ or modify
                the current general conditions of use, in case of
                contradictions, contradictory terms of certain conditions in
                general conditions. In this case, the user must caferully read
                the certain conditions created, for this purpose, by MPRO.
                Access, navigation and use of the Website implies and implies
                acceptance by the user of this legal notice and the terms of
                use. If you do not agree with its content, the user must refrain
                from using the website.
              </p>
              <p
                className="text-muted"
                style={{ fontFamily: "notoSansReg", fontSize: "12px" }}
              >
                2.3 For this purpose, users are defined as individual who
                access, browse, use or participate in services and actifities,
                free or expensive, developed through the website.
              </p>
            </Col>
            <Col></Col>
          </Row>
        </Container>
        <Footer />
        <FooterMobile />
      </div>
    );
  }
}

export default TermCondition;
