import React from 'react'
import { Link } from 'react-router-dom'
import { MUXL, FOOTBALLXL, UFCXL } from '../../../assets/image/match'
import { BOXINGHL2 } from '../../../assets/image/update'


class Highlight extends React.Component{
    render(){
        return(
            <div className='container-fluid'>
                <div className='row'>
                        <div className='d-flex flex-row justify-content-between  col-md pb-2'>
                            <h1 style={{fontSize:'1rem'}}>HIGHLIGHT</h1>
                            <h5 className='view-all'>View all {'>'} </h5>
                        </div>
                    </div>
                <div className='d-flex overflow-auto'>
                    <div className='card-article col-md-4'>
                        <Link to='/highlightpage' className='card text-center bg-dark w-100 text-decoration-none'>
                            <img src={BOXINGHL2.default} alt='pictmu' className='card-img img-fluid' />
                            <div className='card-body'>
                                <p className='card-text text-left'>20 januari 2020</p>
                                <h5 className=' card-title text-left'>Highlight: Mayweather vs Logan Paul</h5>
                            </div>
                        </Link>
                    </div>
                    <div className='card-article col-md-4'>
                        <div className='card text-center bg-dark w-100'>
                            <img src={FOOTBALLXL.default} alt='footballpict' className='card-img img-fluid'/>
                            <div className='card-body'>
                                <p className='card-text text-left'>20 januari 2020</p>
                                <h5 className=' card-title text-left'>Highlight: Chelsea vs Fullham 0-1 </h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-article col-md-4'>
                        <div className='card text-center bg-dark w-100'>
                            <img src={UFCXL.default} alt='ufcpict' className='card-img img-fluid' />
                            <div className='card-body'>
                                <p className='card-text text-left'>20 januari 2020</p>
                                <h5 className=' card-title text-left'>Highlight: Jon Jones vs Israel Adesana 0-1</h5>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        )
    }
}

export default Highlight