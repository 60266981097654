import React from 'react'
import Axios from 'axios'
import { Link, Redirect } from 'react-router-dom'
import Footer from '../../organism/Footer'
import FooterMobile from '../../organism/FooterMobile'
import NavbarComp from '../../organism/NavbarComp'
import NavbarComp2 from '../../organism/NavbarComp2'
import '../App/App.css'
import { API_URL } from '../../../config/common/baseurl'
import Cookise from 'cookie-universal'

const cookies = new Cookise()

class Article extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            articleData: [],
            imgArticle: [],
            titleArticle: [],
            contributorArticle: [],
            dataContributor: []
        }
    }

    

    componentDidMount() {
        const token = {
            headers: { access_token: cookies.get('access_token')}
        }
        // console.log('API ABDUL,', + API_URL)
        Axios.get(`${API_URL}/article`, token)
            .then(async (res) => {
                // console.log('data article yg d dapat', res.data.data)
                // cookie.set('trans_id', res.data.data.transId, { path: '/' })
                // console.log(res.data.data[0].image)
                let articleData = []
                for (var i = 0; i < res.data.data.length; i++) {
                    // console.log(res.data.data[i].statusLike)
                    if (res.data.data)
                        articleData.push(res.data.data[i])
                    this.setState({
                        articleData: articleData,
                        imgArticle: articleData[i].picture,
                        titleArticle: articleData[i].title,
                        contributorArticle: articleData[i].contributor.name,
                        dataContributor: articleData[i].contributor
                    })
                    // console.log('data yg d daoat 2 ', articleData)
                    // console.log(articleData[i].picture)

                    // console.log(res.data.data[i].image)
                }
                // console.log(articleData)

            })
            .catch((err) => console.log(err))
    }

    renderArticle = () => {
        return this.state.articleData.map((val, i) => {
            // console.log('val',val)
            return (
                // <img src={val} alt='onepict' className='card-img img-fluid' key={idx} />
                <div className='container-fluid'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='card-article col-md-4'>
                            <Link to={`/detailarticle/${val._id}`} className='card text-center bg-dark text-decoration-none'>
                                <img src={val.picture} alt='onepict' className='card-img img-fluid' key={i} />
                                <div className='card-body'>
                                    <h5 className='card-title text-left' key={i}>{val.title}</h5>
                                    <div className='d-flex justify-content-between card-text text-muted'>
                                        <div className='d-flex flex-column'>
                                        <span className='text-muted'>{val.contributor.name}</span>
                                        <span className='text-muted'>{val.contributor.role}</span>
                                            </div>
                                        <p className='text-muted'>{val.dateString}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        })
    }
    render() {
        if(cookies.get('access_token') == null || cookies.get('access_token').length < 200 || cookies.get('access_token').length > 250) {
            return <Redirect to='/login' />
        } 
        // console.log('nama kontri', this.state.dataContributor.name)
        // console.log('bedul', this.state.articleData)
        // console.log('titel', this.state.titleArticle)
        // console.log('kontolbrutor', this.state.contributorArticle)
        return (
            <div>
                <NavbarComp2 />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xs text-white text-center' style={{ fontSize: '25px' }}>
                            <div className='article-title'>
                                Get the latest articles about sports from us
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderArticle()}
                <Footer />
                <FooterMobile />
            </div>
        )
    }
}

export default Article