import React from "react";
import { API_URL } from "../../../config/common/baseurl";
import { connect } from "react-redux";
import { logout } from "../../../config/action";
import { Link, Redirect } from "react-router-dom";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import {
  PROFILEXL,
  FAVORITEXL,
  VOUCHERXL,
  LANGUAGEXL,
  LOGOUTXL,
} from "../../../assets/icon";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarComp2 from "../../organism/NavbarComp2";
import Language from "../../organism/Language";
import axios from "axios";
import Cookise from "cookie-universal";

const cookie = new Cookise();

class Pralogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLanguage: false,
      toLogin: false,
      toProfile: false,
      profPict: "",
      iconUser: "",
    };
  }

  componentDidMount = () => {
    const headers = {
      access_token: cookie.get("access_token"),
    };
    axios.get(`${API_URL}/member/detail`, { headers }).then((res) => {
      // console.log('profpict', res.data.data.picture)
      this.setState({ profPict: res.data.data.picture });
      // this.props.getProfile(res.data.data)
    });
    // .catch((err) => console.log(err))
  };

  handleUpdateProfile = () => {
    this.setState({ toProfile: true });
  };

  handleLogout = () => {
    // console.log('token d cookie', cookie.get('access_token'))
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    axios
      .post(`${API_URL}/member/logout`, null, token)
      .then((res) => {
        // console.log(res)
        cookie.remove("access_token", { path: "/" });
        cookie.remove("email", { path: "/" });
        cookie.remove("trans_id", { path: "/" });
        cookie.remove("title", { path: "/" });
        cookie.remove("username", { path: "/" });
        cookie.remove("category", { path: "/" });
        cookie.remove("date", { path: "/" });
        cookie.remove("time", { path: "/" });
        cookie.remove("price", { path: "/" });
        cookie.remove("link", { path: "/" });
        this.setState({ toLogin: true });
        this.props.logout();
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    const { showLanguage, toLogin, toProfile, iconUser, profPict } = this.state;
    if (toLogin) return <Redirect to="/login" />;
    if (toProfile) return <Redirect to="/profile" />;
    return (
      <div>
        <NavbarComp2 />
        <Container
          fluid
          style={{ minHeight: "30vh", color: "white", padding: "100px" }}
        >
          <div className="row">
            <div className="col-xs-1 mb-2">
              {profPict ? (
                <Image
                  fluid
                  src={this.state.profPict}
                  width="100"
                  height="100"
                  alt="ikon user"
                />
              ) : (
                <Image fluid src={PROFILEXL.default} alt="ikon fav" />
              )}
            </div>
            <Col md={3}>
              <Row>
                <p>Enjoy MPRO content</p>
                <div>
                  <Button
                    onClick={this.handleUpdateProfile}
                    style={{ backgroundColor: "#831747" }}
                    className="btnlogin text-white"
                  >
                    Edit Profile
                  </Button>
                </div>
              </Row>
            </Col>
          </div>
        </Container>
        <Container fluid style={{ minHeight: "30vh", color: "white" }}>
          <Row>
            <Link to="/favoritepage" md={4} style={{ display: "flex" }}>
              <div
                onClick={() => this.setState({ iconUser: !iconUser })}
                className="mt-3 mr-3"
              >
                <Image fluid src={FAVORITEXL.default} alt="ikon fav" />
              </div>
              <div>
                <h5 className="pt-2" style={{ fontFamily: "microextend" }}>
                  Favorite
                </h5>
                <p className="text-muted" style={{ fontSize: "13px" }}>
                  your favorite match, replay and highlight
                </p>
              </div>
            </Link>
          </Row>
          {/* <Row>
                        <Col md={4} style={{ display: 'flex' }} onClick={() => this.setState({ showLanguage: !showLanguage })}>
                            <div className='mt-3 mr-3'>
                                <Image fluid src={LANGUAGEXL.default} alt='ikon fav' />
                            </div>
                            <div >
                                <h5 className='pt-2' style={{ fontFamily: 'microextend' }}>Language</h5>
                                <p className='text-muted' style={{ fontSize: '13px' }}>you can change the language</p>
                            </div>
                        </Col>
                    </Row> */}
          <Row>
            <Link to="/purchasehistory" md={4} style={{ display: "flex" }}>
              <div className="mt-3 mr-3">
                <Image fluid src={VOUCHERXL.default} alt="ikon fav" />
              </div>
              <div>
                <h5 className="pt-2" style={{ fontFamily: "microextend" }}>
                  Purchase History
                </h5>
                <p className="text-muted" style={{ fontSize: "13px" }}>
                  All event ticket purchase lists are here
                </p>
              </div>
            </Link>
          </Row>
          <Row>
            <Col md={4} onClick={this.handleLogout} style={{ display: "flex" }}>
              <btn className="my-3 mr-3">
                <Image fluid src={LOGOUTXL.default} alt="ikon fav" />
              </btn>
              <div>
                <h5 className="pt-3" style={{ fontFamily: "microextend" }}>
                  Sign Out
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
        <FooterMobile />
        {showLanguage ? <Language /> : null}
      </div>
    );
  }
}

export default connect(logout)(Pralogin);
