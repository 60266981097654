import React from 'react'
import { ICSUCCESS } from '../../../assets/logo'
import Footer from '../../organism/Footer'
import FooterMobile from '../../organism/FooterMobile'
import NavbarComp from '../../organism/NavbarComp'
import '../App/App.css'

class Paymentsuccess extends React.Component {
    render() {
        return (
            <div>
                <NavbarComp />
                <div className='payment'>
                    <div className='container text-center'>
                        <div className='payment-section row'>
                            <div className='col-md-12'>
                                <h1>Payment success</h1>
                                <img src={ICSUCCESS.default}
                                    height='80'
                                    width='110'
                                    alt='payment success'/>
                                <h5 className='pt-5'>Your Payment was Successfully Completed</h5>
                                <button className='bton px-3 py-1 mt-3' block>Continue Explore Mpro </button>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
                <FooterMobile />    
            </div>
        )
    }
}

export default Paymentsuccess