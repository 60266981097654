import React from 'react'
import { Button } from 'react-bootstrap'

class PageNotFound extends React.Component {
    handleGoBack = () => {
        window.history.back()
    }
    render() {
        return (
            <div className='d-flex flex-column align-items-center pt-5' style={{ backgroundColor: 'black'}}>
                <h1 >
                    OOPS SORRY
                </h1>
                <h1 style={{ fontSize:'20px'}}>
                    404 PAGE NOT FOUND
                </h1>
                <div>
                    <Button
                        onClick={this.handleGoBack}
                        style={{ backgroundColor: '#831747' }}
                        className='btnlogin text-white'>Go Back</Button>
                </div>
            </div>
        )
    }
}

export default PageNotFound