import React from "react";
import axios from "axios";
import Footer from "../../organism/Footer";
import NavbarComp2 from "../../organism/NavbarComp2";
import FooterMobile from "../../organism/FooterMobile";
import { Image, Tab } from "react-bootstrap";
import { NavLink, Redirect } from "react-router-dom";
import { LIVE } from "../../../assets/image/live";
import { API_URL } from "../../../config/common/baseurl";
import Tabs from "../../organism/Tabs";
import "../App/App.css";
import Cookies from "cookie-universal";

const cookies = new Cookies();

class Live extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataStreaming: [],
      date: [],
    };
  }

  componentDidMount = () => {
    const token = {
      headers: { access_token: cookies.get("access_token") },
    };
    axios
      .get(`${API_URL}/streaming/date-range`, token)
      .then((res) => {
        // console.log("data", res.data.data);
        let date = [];
        let dataStreaming = [];

        // console.log("data",typeof data[5].data)
        for (let i = 0; i < res.data.data.length; i++) {
          date.push(res.data.data[i].dateString);
          // if (typeof data[i].data !== "string" && data[i].data.length !== 0) {
          //     dataStreaming.push(data[i])
          // }
          if (typeof res.data.data[i].data === "string") {
            dataStreaming.push(res.data.data[i]);
          } else if (
            typeof res.data.data[i].data !== "string" &&
            res.data.data[i].data.length !== 0
          ) {
            // dataStreaming.push(data[i])
            for (let j = 0; j < res.data.data[i].data.length; j++) {
              // console.log("data dalam array")
              dataStreaming.push(res.data.data[i].data[j]);
            }
          }
        }
        this.setState({ date: date });
        this.setState({ dataStreaming: dataStreaming });
        console.log("dapet data", this.state.date);
        console.log("dapet streamingf", this.state.dataStreaming);

        // this.setState({ data: res.data.data.today });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDate = () => {
    return this.state.dataStreaming.map((val, i) => {
      if (val.data) {
        return (
          <div className="d-flex flex-column">
            <h5>{val.dateString}</h5>
            <p>{val.data}</p>
          </div>
        );
      } else {
        return (
          <div className="bloc-tabs">
            <h5 className="tabs active-tabs">{val.dateString}</h5>
            <img
              style={{ maxWidth: "50px" }}
              src={val.thumbnail}
              alt="thumbnail"
              fluid
            />
            <span>{val.description}</span>

            <p>{val.data}</p>
          </div>
        );
      }
    });
  };

  handleCalendarEvent = () => {
    return this.state.dataStreaming.map((val, i) => {
      return (
        <div>
          <ul className="col-md-4 align-items-center list-unstyled d-flex justify-content-between p-1">
            <li className="schedule-content">
              <div className="d-flex text-center">
                <span>{val.dateString}</span>
              </div>
            </li>
          </ul>
          {/* <p>{val.description}</p> */}
        </div>
      );
    });
  };

  render() {
    console.log("data striming nih", this.state.dataStreaming);
    if (
      cookies.get("access_token") == null ||
      cookies.get("access_token").length < 200 ||
      cookies.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <NavbarComp2 />
        <div></div>
        <div className="d-flex justify-content-center  flex-row bg-black p-2">
          {this.handleCalendarEvent()}
        </div>
        {/* <ul className="col-md-4 align-items-center list-unstyled d-flex justify-content-between p-1">
          <li className="schedule-content">
            <div to="/" className="d-flex flex-column align-items-center">
              <span>sat</span>
              <span
                style={{
                  fontSize: "9px",
                  fontFamily: "notoSansBold",
                  fontWeight: "bolder",
                }}
              >
                23 jan
              </span>
            </div>
          </li>
          <li className="schedule-content">
            <div to="/" className="d-flex flex-column align-items-center">
              <span>sun</span>
              <span
                style={{
                  fontSize: "9px",
                  fontFamily: "notoSansBold",
                  fontWeight: "bolder",
                }}
              >
                24 jan
              </span>
            </div>
          </li>
          <li className="schedule-content">
            <div to="/" className="d-flex flex-column align-items-center">
              <span>mon</span>
              <span
                style={{
                  fontSize: "9px",
                  fontFamily: "notoSansBold",
                  fontWeight: "bolder",
                }}
              >
                25 jan
              </span>
            </div>
          </li>
          <li className="schedule-content">
            <div to="/" className="d-flex flex-column align-items-center">
              <span>today</span>
              <span
                style={{
                  fontSize: "9px",
                  fontFamily: "notoSansBold",
                  fontWeight: "bolder",
                }}
              >
                26 jan
              </span>
            </div>
          </li>
          <li className="schedule-content">
            <div to="/" className="d-flex flex-column align-items-center">
              <span>wed</span>
              <span
                style={{
                  fontSize: "9px",
                  fontFamily: "notoSansBold",
                  fontWeight: "bolder",
                }}
              >
                27 jan
              </span>
            </div>
          </li>
          <li className="schedule-content">
            <div to="/" className="d-flex flex-column align-items-center">
              <span>thu</span>
              <span
                style={{
                  fontSize: "9px",
                  fontFamily: "notoSansBold",
                  fontWeight: "bolder",
                }}
              >
                28 jan
              </span>
            </div>
          </li>
          <li className="schedule-content">
            <div to="/" className="d-flex flex-column align-items-center">
              <span>fri</span>
              <span
                style={{
                  fontSize: "9px",
                  fontFamily: "notoSansBold",
                  fontWeight: "bolder",
                }}
              >
                29 jan
              </span>
            </div>
          </li>
        </ul> */}
        <div className="container-fluid py-2">
          <div
            className="row d-flex justify-content-center"
            style={{ backgroundColor: "black", width: "100%", height: "3rem" }}
          ></div>

          <div className="row">
            <div className="col d-flex flex-row justify-content-center">
              <ul className="calendar-title d-flex justify-content-around list-unstyled pt-3">
                <li className="p-1 text-muted">
                  {/* {this.handleDate()} */}
                  no live today
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col" style={{ minHeight: "50vh" }}>
              <div className="d-flex justify-content-center m-auto pt-5">
                {this.state.data}
              </div>
            </div>
          </div>
        </div>
        <div className="bloc-tabs"></div>
        <Footer />
        <FooterMobile />
      </div>
    );
  }
}

export default Live;
