import React from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import NavbarBack from '../../organism/NavbarBack'

class Vouchercode extends React.Component {
    render() {
        return (
            <div>
                <NavbarBack />
                <Container fluid style={{ minHeight: '90vh', backgroundColor: 'black' }}>
                    <Row>
                        <Col></Col>
                        <Col md={5} className='text-center mt-5'>
                            <h6> USE VOUCHER CODE </h6>
                            <Form className='mt-5'>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Enter your voucher code"
                                        style={{backgroundColor:'black', padding:'20px', borderRadius:'10px'}} />
                                </Form.Group>
                                <Button
                                    style={{backgroundColor:'#831747', border:'none', fontFamily:'microextendBold,', borderRadius:'10px', cursor:'pointer'}}
                                    type="submit" block>
                                    PROCEED
                                </Button>
                                    <Form.Text className="text-muted">
                                        Need help with your voucher code? 
                                    </Form.Text>
                            </Form>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Vouchercode