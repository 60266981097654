import React from "react";
import axios from "axios";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarBack from "../../organism/NavbarBack";
import { LIVE } from "../../../assets/image/live";
import { ICPREMIUM } from "../../../assets/icon/buttom-bar-mobile/index";
import { API_URL } from "../../../config/common/baseurl";
import { Form, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Cookise from "cookie-universal";

const cookie = new Cookise();

const domain = "https://mpro.world/";

class PaymentEwallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toDetailPayment: false,
      bankName: "",
      selected: "",
      toPurchaseHistory: false,
      toHome: false,
    };
  }

  handleOvo = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const transID = cookie.get("trans_id");
    const ewalletName = "OVO";
    const price = cookie.get("price");
    const phoneNumber = this.refs.ovonumber.value;
    const redirectUrl = domain;
    console.log("hp", phoneNumber, transID, price);
    axios
      .post(
        `${API_URL}/payment/ewallet`,
        {
          paymentMethod: "EWALLET",
          ewalletName: ewalletName,
          transactionId: transID,
          amount: price,
          phoneNumber: phoneNumber,
          redirectUrl: redirectUrl,
        },
        token
      )
      .then((res) => {
        console.log("url desktop", res.data.data.data.checkoutUrlMobile);
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        // console.log(res.data);
        // if (res.data.data.Error) {
        //   alert("transaction Id already used");
        //   this.setState({ toHome: true });
        // } else
        if (isMobile) {
          window.open(res.data.data.data.checkoutUrlMobile);
          // this.setState({ toPurchase: true });
        } else {
          alert("please scan barcode");
          window.open(res.data.data.data.checkoutUrlDesktop);
          // this.setState({ toPurchase: true });
        }
        this.setState({ toPurchaseHistory: true });
      })
      .then((err) => {
        alert("transaction Id already used");
        this.setState({ toHome: true });
        // console.log(err)
      });
  };

  handleGopay = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const transID = cookie.get("trans_id");
    const price = cookie.get("price");
    axios
      .post(
        `${API_URL}/payment/gopay`,
        {
          paymentMethod: "GOPAY",
          transactionId: transID,
          amount: price,
        },
        token
      )
      .then((res) => {
        console.log("url desktop", res.data.data.data.checkoutUrlMobile);
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        // if (res.data.data.Error) {
        //   alert("transaction Id already used");
        //   this.setState({ toHome: true });
        // } else
        if (isMobile) {
          window.location.href(res.data.data.data.checkoutUrlMobile);
          // this.setState({ toPurchase: true });
        } else {
          alert("please scan barcode");
          window.open(res.data.data.data.checkoutUrlDesktop);
          // this.setState({ toPurchase: true });
        }
        this.setState({ toPurchase: true });
      })
      .catch((err) => {
        alert("transaction Id already used");
        this.setState({ toHome: true });
        // console.log(err)
      });
  };

  handleShopeepay = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const transID = cookie.get("trans_id");
    const ewalletName = "SHOPEEPAY";
    const price = cookie.get("price");
    // const phoneNumber = this.refs.shopeepaynumber.value;
    const redirectUrl = domain;
    // console.log('hp', transID, price, redirectUrl)
    axios
      .post(
        `${API_URL}/payment/ewallet`,
        {
          paymentMethod: "EWALLET",
          ewalletName: ewalletName,
          transactionId: transID,
          amount: price,
          // phoneNumber: phoneNumber,
          redirectUrl: redirectUrl,
        },
        token
      )
      .then((res) => {
        console.log(res.data.success);
        // console.log(res.status);
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        // if (!res.data.success) {
        //   alert("transaction Id already used");
        //   this.setState({ toHome: true });
        // } else
        if (isMobile) {
          window.open(res.data.data.data.checkoutUrlMobile);
          // this.setState({ toPurchase: true });
        } else {
          alert("please scan barcode");
          window.open(res.data.data.data.checkoutUrlDesktop);
          // this.setState({ toPurchase: true });
        }
        // this.setState({ toPurchaseHistory: true });
      })
      .catch((err) => {
        // console.log(err);
        // console.log(err.data);
        alert("transaction Id already used");
        this.setState({ toHome: true });
      });
  };

  handleCimbClick = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const transID = cookie.get("trans_id");
    const ewalletName = "CIMBCLICK";
    const price = cookie.get("price");
    // const phoneNumber = this.refs.dananumber.value
    const redirectUrl = domain;
    console.log("hp", transID, price, redirectUrl);
    axios
      .post(
        `${API_URL}/payment/ewallet`,
        {
          paymentMethod: "EWALLET",
          ewalletName: ewalletName,
          transactionId: transID,
          amount: price,
          // phoneNumber: phoneNumber,
          redirectUrl: redirectUrl,
        },
        token
      )
      .then((res) => {
        // this.setState({ toPurchaseHistory: true });
        console.log(res.data);
        console.log(res.data.data.data.checkoutUrlMobile);
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        // if (res.data.data.Error) {
        //   alert("transaction Id already used");
        //   this.setState({ toHome: true });
        // } else
        if (isMobile) {
          window.open(res.data.data.data.checkoutUrlMobile);
        } else {
          window.open(res.data.data.data.checkoutUrlDesktop);
        }
        this.setState({ toPurchaseHistory: true });
      })
      .catch((err) => {
        // console.log(err)
        alert("transaction Id already used");
        this.setState({ toHome: true });
      });
  };

  handleDana = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const transID = cookie.get("trans_id");
    const ewalletName = "DANA";
    const price = cookie.get("price");
    // const phoneNumber = this.refs.dananumber.value
    const redirectUrl = domain;
    // console.log('hp', transID, price, redirectUrl)
    axios
      .post(
        `${API_URL}/payment/ewallet`,
        {
          paymentMethod: "EWALLET",
          ewalletName: ewalletName,
          transactionId: transID,
          amount: price,
          // phoneNumber: phoneNumber,
          redirectUrl: redirectUrl,
        },
        token
      )
      .then((res) => {
        this.setState({ toPurchaseHistory: true });
        console.log(res.data);
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        // if (res.data.data.Error) {
        //   alert("transaction Id already used");
        //   this.setState({ toHome: true });
        // } else
        if (isMobile) {
          window.open(res.data.data.data.checkoutUrlMobile);
        } else {
          window.open(res.data.data.data.checkoutUrlDesktop);
        }
        this.setState({ toPurchaseHistory: true });
      })
      .then((err) => {
        // console.log(err)
        alert("transaction Id already used");
        this.setState({ toHome: true });
      });
  };

  handleLinkAja = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const transID = cookie.get("trans_id");
    const ewalletName = "LINKAJA";
    const price = cookie.get("price");
    // const phoneNumber = this.refs.linkajanumber.value
    const redirectUrl = domain;
    // console.log('hp', transID, price, redirectUrl)
    axios
      .post(
        `${API_URL}/payment/free`,
        {
          paymentMethod: ewalletName,
          // ewalletName: ewalletName,
          transactionId: transID,
          // amount: price,
          // phoneNumber: phoneNumber,
          // redirectUrl: redirectUrl,
        },
        token
      )
      .then((res) => {
        this.setState({ toPurchaseHistory: true });
        // console.log(res.data)
        // if (res.data.data.Error) {
        //   alert("transaction Id already used");
        //   this.setState({ toHome: true });
        // } else {
        //   window.open(res.data.data.data.checkoutUrlDesktop);
        //   this.setState({ toPurchaseHistory: true });
        // }
      })
      .then((err) => {
        // console.log(err)
      });
  };

  handleChange = (e) => {
    this.setState({ bankName: e.target.value });
  };

  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    const { toPurchaseHistory, toHome } = this.state;
    if (toPurchaseHistory) return <Redirect to="/purchasehistory" />;
    if (toHome) return <Redirect to="/home" />;
    return (
      <div className="big-container" style={{ backgroundColor: "black" }}>
        <NavbarBack />
        <div className="container-fluid">
          <div className="row justify-content-md-center ">
            <div className="col-md-4 d-flex">
              <div className="pr-2">
                <img fluid src={ICPREMIUM.default} alt="icon-premium" />
              </div>
              <div className=" pt-2">
                <h5 className="title-livedetail">{cookie.get("title")}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <p className="date-livedetail text-muted">
                {cookie.get("time")} - {cookie.get("date")}
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail pt-3">Details :</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Ticket ID</p>
                <p className="price-livedetail">{cookie.get("trans_id")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Content</p>
                <p className="price-livedetail">{cookie.get("title")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Category</p>
                <p className="price-livedetail">{cookie.get("category")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Email</p>
                <p className="price-livedetail">{cookie.get("email")}</p>
              </div>
              <div className="row-md-4 d-flex justify-content-between">
                <p className="price-livedetail text-muted">Total</p>
                <p className="price-livedetail">{cookie.get("price")}</p>
              </div>
              <div class="row-md-4 d-flex justify-content-between">
                <div class="col text-white">2. Choose Ewallet Payment</div>
              </div>
              <div className="container pt-2">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="d-flex justify-content-between mb-0">
                        <button
                          className="btn btn-link btn-block text-left text-dark"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          OVO
                        </button>
                        <span>
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </h2>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body text-white text-center">
                        <Form>
                          <Form.Label className="pt-3">
                            <h6>input your phone number</h6>
                          </Form.Label>
                          <Form.Control
                            ref="ovonumber"
                            id="ovonumber"
                            // onChange={(e) => this.userValid(e)}
                            type="text"
                            placeholder="+62xx-xxxx-xxxx"
                            style={{
                              color: "white",
                              backgroundColor: "black",
                              padding: "20px",
                              borderRadius: "10px",
                            }}
                          />
                        </Form>
                        <Button
                          onClick={this.handleOvo}
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#faa21b",
                            border: "none",
                            fontFamily: "microextend",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="d-flex justify-content-between mb-0">
                        <button
                          className="btn btn-link btn-block text-left text-dark collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Shopee pay
                        </button>
                        <span>
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body text-center">
                        <Button
                          onClick={this.handleShopeepay}
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#faa21b",
                            border: "none",
                            fontFamily: "microextend",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="d-flex justify-content-between mb-0">
                        <button
                          className="btn btn-link btn-block text-left text-dark collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          DANA
                        </button>
                        <span>
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body text-center">
                        <Button
                          onClick={this.handleDana}
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#faa21b",
                            border: "none",
                            fontFamily: "microextend",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="d-flex justify-content-between mb-0">
                        <button
                          className="btn btn-link btn-block text-left text-dark collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Link Aja
                        </button>
                        <span>
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </h2>
                    </div>
                    <div
                      id="collapseFour"
                      className="collapse"
                      aria-labelledby="headinFour"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body text-center">
                        <Button
                          onClick={this.handleLinkAja}
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#faa21b",
                            border: "none",
                            fontFamily: "microextend",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div> */}
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h2 className="d-flex justify-content-between mb-0">
                        <button
                          className="btn btn-link btn-block text-left text-dark collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          GOPAY
                        </button>
                        <span>
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </h2>
                    </div>
                    <div
                      id="collapseFive"
                      className="collapse"
                      aria-labelledby="headinFive"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body text-center">
                        <Button
                          onClick={this.handleGopay}
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#faa21b",
                            border: "none",
                            fontFamily: "microextend",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingSix">
                      <h2 className="d-flex justify-content-between mb-0">
                        <button
                          className="btn btn-link btn-block text-left text-dark collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          CIMB CLICK
                        </button>
                        <span>
                          <i class="fas fa-sort-down"></i>
                        </span>
                      </h2>
                    </div>
                    <div
                      id="collapseSix"
                      className="collapse"
                      aria-labelledby="headinSix"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body text-center">
                        <Button
                          onClick={this.handleCimbClick}
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#faa21b",
                            border: "none",
                            fontFamily: "microextend",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <FooterMobile />
      </div>
    );
  }
}

export default PaymentEwallet;
