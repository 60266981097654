import React from "react";
import axios from "axios";
import { Link, NavLink, Redirect } from "react-router-dom";
import { API_URL } from "../../../config/common/baseurl";
import Article from "../../organism/Article";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarComp2 from "../../organism/NavbarComp2";
import { ONE } from "../../../assets/image/podcast";
import Cookise from "cookie-universal";

const cookie = new Cookise();

class LibraryPhoto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArticle: [],
    };
  }
  componentDidMount() {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const data = { role: "MPRO Contributor" };
    axios
      .post(`${API_URL}/photo/contributor-role`, data, token)
      .then(async (res) => {
        // console.log('data contributor', res.data)
        let dataArticle = [];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data) dataArticle.push(res.data.data[i]);
          // console.log('data title', dataVideoContributor[i].title)
          // console.log('data contributor', dataVideoContributor[i].contributor.name)
          this.setState({
            dataArticle: dataArticle,
          });
        }
      })
      .catch((err) => console.log(err));
  }

  renderDataArticle = () => {
    return this.state.dataArticle.map((val, i) => {
      //   console.log("ceklibrayr", this.state.dataArticle[i].photo);
      return (
        <div className="container-fluid">
          <div className="d-flex justify-content-center overflow-auto">
            <div className="col-md-4">
              <Link
                to={`/pictures/${val._id}`}
                className="card text-center bg-dark"
              >
                <img
                  src={this.state.dataArticle[i].photo[0]}
                  className="card-img img-fluid"
                  alt="onepict"
                />
                <div className="card-body">
                  <h5 className=" card-title text-left text-center" key={i}>
                    {this.state.dataArticle[i].title}
                  </h5>
                  <div className="d-flex">
                    <img
                      className="rounded-circle img-fluid"
                      src={this.state.dataArticle[i].contributor.photo}
                      style={{ maxHeight: "50px", maxWidth: "50px" }}
                      alt="contributor"
                    />
                    <div className="pl-2">
                      <h4
                        className="card-text text-white text-left"
                        style={{ fontSize: "12px", paddingLeft: "2px" }}
                        key={i}
                      >
                        {this.state.dataArticle[i].contributor.name}
                      </h4>
                      <h4
                        className="card-text text-white text-left text-muted"
                        style={{ fontSize: "12px", paddingLeft: "2px" }}
                      >
                        {this.state.dataArticle[i].dateString}
                      </h4>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    const { location } = this.props;
    const { pathname } = location;
    const { dataVideoContributor, dataContributorName } = this.state;
    // console.log('data article', this.state.dataArticle)
    return (
      <div>
        <NavbarComp2 />
        <div className="container-fluid pt-2">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-4">
              <ul className="title-library d-flex justify-content-around list-unstyled">
                <li>
                  <NavLink to="/libraryarticle">
                    <span
                      style={{
                        color: `${
                          pathname === "/libraryarticle" ? "#FAA21B" : "#FFFFFF"
                        }`,
                      }}
                    >
                      Articles
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/libraryphoto">
                    <span
                      style={{
                        color: `${
                          pathname === "/libraryphoto" ? "#FAA21B" : "#FFFFFF"
                        }`,
                      }}
                    >
                      Pictures
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/library">
                    <span
                      style={{
                        color: `${
                          pathname === "/library" ? "#FAA21B" : "#FFFFFF"
                        }`,
                      }}
                    >
                      Videos
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex text-center text-white justify-content-center">
                <span className="title-content">
                  Check out the best photo galleries about sports from our
                  contributors.
                </span>
              </div>
            </div>
          </div>
        </div>
        {this.renderDataArticle()}
        <Footer />
        <FooterMobile />
      </div>
    );
  }
}

export default LibraryPhoto;
