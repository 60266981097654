import React from "react";
import axios from "axios";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarBack from "../../organism/NavbarBack";
import { ICPREMIUM } from "../../../assets/icon/buttom-bar-mobile/index";
import { Link, Redirect } from "react-router-dom";
import { API_URL } from "../../../config/common/baseurl";
import Cookise from "cookie-universal";

const cookie = new Cookise();

class WatchLiveStreaming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataApi: "",
      dataUsername: "",
      dataToken: "",
    };
  }

  render() {
    // console.log("ling bedul", cookie.get("link"));
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    const { dataDetaills, toPayment, toWatchStream } = this.state;
    if (toWatchStream) return <Redirect to="/watchls" />;
    if (toPayment) return <Redirect to="/paynow" />;

    return (
      <div style={{ backgroundColor: "black" }}>
        <NavbarBack />
        <iframe
          allowFullScreen
          className="live-picture"
          src={cookie.get("link")}
          ref="iframe"
        />
        <div className="container-fluid">
          <div className="row align-items-center flex-column">
            <div className="col-md-4 d-flex">
              <div className="pr-2">
                <img fluid src={ICPREMIUM.default} alt="icon-premium" />
              </div>
              <div className=" pt-2">
                <h5 className="title-livedetail">{cookie.get("title")}</h5>
              </div>
            </div>
            <div className="col-md-4">
              {/* <p className='date-livedetail text-muted'>{dataDetaills.dateString}</p> */}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-md-4 ">
              <div className="d-flex flex-column">
                <p className="date-livedetail text-muted">Information</p>
                <p className="date-livedetail" style={{ opacity: "0.8" }}>
                  {/* {dataDetaills.description} */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterMobile />
        <Footer />
      </div>
    );
  }
}

export default WatchLiveStreaming;
