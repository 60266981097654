import React, { useRef } from 'react'
import Axios from 'axios'
import { API_URL } from '../../../config/common/baseurl'
import { Link, Redirect } from 'react-router-dom'
import { Container, Row, Col, Form, Button, Image, Modal, CloseButton } from 'react-bootstrap'
import { LOGOUTXL, FAVORITEXL, VOUCHERXL, LANGUAGEXL } from '../../../assets/icon'
import Footer from '../../organism/Footer'
import FooterMobile from '../../organism/FooterMobile'
import NavbarBack from '../../organism/NavbarBack'
import firebase from '../../../config/firebase'
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import '../App/App.css'
import Cookies from 'cookie-universal'
import { connect } from 'react-redux'
import { login } from '../../../config/action'
import ReCAPTCHA from "react-google-recaptcha";

const cookies = new Cookies()

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSignedIn: false,
            visible: false,
            email: '',
            password: '',
            toHome: false,
            showLanguage: false,
            isValidate: false,
            isVerified: false,
            captcha: '',
            checked: false,
            loginError: [false, ""],
            logOutOtherDevice: [false, ""],
            toLogin: false,
            anotherDevice : "This Account was login in another device!"

        }
    }
    handleOnChangeRecaptcha = (value) => {
        // console.log("Captcha value:", value)
        this.setState({ captcha: value })
    }
    handleChangeText = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleLoginSubmit = (e) => {
        let email = this.refs.email.value
        let password = this.refs.password.value
        let captcha = this.state.captcha
        if (!email || !password) return this.setState({ loginError: [true, 'Please Input All Form'] })

        if (this.state.checked) return alert('plese checked your captcha')

        Axios.post(`${API_URL}/member/login`, {
            email: email,
            password: password,
            grecaptcharesponse: captcha
        }
        )
            .then((res) => {
                // console.log('datalogin', res.data) 
                cookies.set('access_token', res.data.access_token, { path: '/' })
                cookies.set('username', res.data.data.username, { path: '/' })
                cookies.set('photopict', res.data.data.picture, { path: '/' })
                this.props.login(res.data.data)
                this.setState({ toHome: true })
            })
            .catch((err) => {
                // console.log(err.response)
                if(alert(err.response.data.message)) return this.setState({ captcha : '' })
                
                if(this.state.anotherDevice === err.response.data.message) return this.setState({ logOutOtherDevice: [true, err.response.data.message] })
                
            })
    }

    handleLogout = () => {
        let email = this.refs.email.value
        Axios.post(`${API_URL}/member/force-logout`, {
            email: email
        }
        )
            .then((res) => {
                // console.log(res)
                // this.setState({ toLogin: true })
                cookies.remove('access_token', { path: '/' })
                this.setState({ logOutOtherDevice: [false, ""] })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    render() { 
        const { visible, toHome, showLanguage, isVisible, reRef, loginError, logOutOtherDevice, toLogin } = this.state
        // console.log('access', cookies.get('access_token'))
        // console.log('access', cookies.get('access_token').length)
        if (toHome) return <Redirect to='/home' />
        if (toLogin) return <Redirect to='/login'/>
        return (
            <div>
                <div style={{ backgroundColor: 'black' }}>
                    <Row>
                        <Col className='d-flex justify-content-between' sm={12}>
                            <Link to='/' style={{ color: 'white', fontFamily: 'microextend', margin: '20px', cursor: 'pointer' }}>
                                <i className="fas fa-chevron-left"></i> Back
                            </Link>
                        </Col>
                    </Row>
                </div>
                <Container fluid style={{ minHeight: '80vh', backgroundColor: 'black' }}>
                    <Row className="justify-content-md-center">
                        <Col md={3}>
                            <Form className='mt-5'>
                                <Form.Group>
                                    <Form.Label>
                                        <h6>
                                            Email
                                        </h6>
                                    </Form.Label>
                                    <Form.Control
                                        ref='email'
                                        type="email"
                                        placeholder="email@email.com"
                                        style={{ color: 'white', backgroundColor: 'black', padding: '20px', borderRadius: '10px' }} />
                                    <Form.Label className='mt-3'>
                                        <h6>
                                            Password
                                        </h6>
                                    </Form.Label>
                                    <Form.Control
                                        ref='password'
                                        type={visible ? "text" : "password"}
                                        placeholder="minimum 6 character"
                                        style={{ color: 'white', backgroundColor: 'black', padding: '20px', borderRadius: '10px' }} />
                                    <Form.Text className="text-muted">
                                        <Row>
                                            <Col onClick={() => this.setState({ visible: !visible })} className='inputicon' md={1} style={{ cursor: 'pointer', marginLeft: 'auto' }}>
                                                {visible ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}></Col>
                                            {/* <Col md={4} style={{ cursor: 'pointer', }}>Lupa Password? </Col> */}
                                        </Row>
                                    </Form.Text>
                                    <Link to='./forgotpass'>
                                        <p >forgot passsword?</p>
                                    </Link>
                                </Form.Group>
                                <ReCAPTCHA
                                    sitekey="6Lf1838cAAAAACxy9QtfMx4ZE6Lxzbd-gfp_VOrI"
                                    // onChange={(e)=> this.handleCheckRecaptcha(e)}
                                    onChange={this.handleOnChangeRecaptcha}
                                />
                                <Button
                                    onClick={this.handleLoginSubmit}
                                    style={{
                                        backgroundColor: '#faa21b', border: 'none',
                                        fontFamily: 'microextend', borderRadius: '10px', cursor: 'pointer',
                                        marginTop:'15px'
                                    }}
                                    block>
                                    LOGIN
                                </Button>
                                {/* <p className='text-center mt-5'>Another way:</p> */}
                                {/* <StyledFirebaseAuth
                                    uiConfig={this.uiConfig}
                                    firebaseAuth={firebase.auth()}
                                /> */}
                                <div className='d-flex justify-content-center mt-3 pb-5'>
                                    <p>Dont have an account?</p>
                                    <Link style={{ textDecoration: 'none' }} to='/register'>
                                        <h6 className='pt-1 pl-3'>Register</h6>
                                    </Link>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <Footer />
                {/* <FooterMobile /> */}
                <Modal show={loginError[0]} onHide={() => this.setState({ loginError: [false, ""] })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <p style={{ color: 'black' }}>{loginError[1]}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.setState({ loginError: [false, ""] })}>
                            Okay
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={logOutOtherDevice[0]} onHide={() => this.setState({ logOutOtherDevice: [false, ""] })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <p style={{ color: 'black' }}>{logOutOtherDevice[1]}</p>
                        <p style={{ color: 'black' }}>Do you want to log out it?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleLogout}>
                            Log Out
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return{
        username: state.user.username 
    }
}

export default connect(mapStateToProps , { login })(Login)