import React from 'react'
import { Link } from 'react-router-dom'
import './Trending.css'
import { MUXL, FOOTBALLXL, UFCXL } from '../../../assets/image/match'

class Trending extends React.Component {
    render() {
        return (
            <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md pb-2 '>
                            <h1>TRENDING</h1>
                        </div>
                        <div className='col-md-1 pb-4'>
                            <p>view all</p>
                        </div>
                    </div>
                    <div className='d-flex overflow-auto'>
                        <div className='col-sm-4'>
                            <Link to='/trendingpage' className='card text-center bg-dark '>
                                <img src={MUXL.default} className='card-img img-fluid' alt='emyu' />
                                <div className='card-body'>
                                    <p className='card-text text-left'>{new Date().toLocaleTimeString()}</p>
                                    <h5 className=' card-title text-left'>Highlight: Manchester United vs Liverpool 3-2</h5>
                                </div>
                            </Link>
                        </div>
                        <div className='col-sm-4'>
                            <div className='card text-center bg-dark  '>
                                <img src={FOOTBALLXL.default} alt='img football' className='card-img img-fluid'/>
                                <div className='card-body'>
                                    <p className='card-text text-left'>20 januari 2020</p>
                                    <h5 className=' card-title text-left'>Highlight: Chelsea vs Fullham 0-1 </h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='card text-center bg-dark '>
                                <img src={UFCXL.default} alt='img ufc' className='card-img img-fluid' />
                                <div className='card-body '>
                                    <p className='card-text text-left'>20 januari 2020</p>
                                    <h5 className=' card-title text-left'>Highlight: Jon Jones vs Israel Adesana 0-1</h5>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}

export default Trending