import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import FooterMobile from "../../organism/FooterMobile";
import NavbarBack from "../../organism/NavbarBack";
import Footer from "../../organism/Footer";
import { LIVE } from "../../../assets/image/live";
import { API_URL } from "../../../config/common/baseurl";
import Cookise from "cookie-universal";

const cookie = new Cookise();

class PurchaseHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPending: [],
    };
  }

  componentDidMount() {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    axios
      .get(`${API_URL}/history/member`, token)
      .then((res) => {
        // console.log('data', res.data.data)
        // console.log('status', res.data.data[i].streaming)
        const dataPending = [];
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data) dataPending.push(res.data.data[i]);
          // console.log('streaming',res.data.data[i].streaming)
          this.setState({ dataPending: dataPending });
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  renderHistory = () => {
    return this.state.dataPending.map((val, i) => {
      // console.log('thumbail', val.streaming._id)
      return (
        <Link
          // className="link"
          className="d-flex bg-dark p-1 mb-4"
          to={`/detaills/${val.streaming._id}`}
          style={{ borderRadius: "10px" }}
        >
          <img
            fluid
            className="p-2"
            src={val.streaming.thumbnail}
            style={{
              maxHeight: "110px",
              minHeight: "110px",
              minWidth: "155px",
              maxWidth: "155px",
            }}
            alt="detail-live"
            key={i}
          />
          <div className="col d-flex flex-column">
            <div className="col d-flex p-1 justify-content-between ">
              <span
                style={{
                  color: "white",
                  fontFamily: "notoSansReg",
                  fontSize: "13px",
                }}
              >
                {val.streaming.dateString}
              </span>
              <span
                className="rounded p-1"
                style={{
                  fontFamily: "microextendBold",
                  fontSize: "10px",
                  color: "#faa21b",
                  background: "rgba(250, 162, 27, 0.2)",
                }}
              >
                {val.status}
              </span>
            </div>
            <span style={{ color: "white" }}>{val.streaming.title}</span>
            <div className="d-flex p-1 justify-content-between ">
              <span
                style={{
                  fontFamily: "notoSansReg",
                  fontSize: "13px",
                  paddingTop: "1px",
                  color: "white",
                }}
              >
                {val.streaming.competition.name}
              </span>
              <span
                className="rounded"
                style={{
                  fontFamily: "microextendBold",
                  fontSize: "10px",
                  color: "#faa21b",
                  paddingTop: "5px",
                }}
              >
                Rp. {val.streaming.rupiahPrice} / $ {val.streaming.dollarPrice}
              </span>
            </div>
            <div>
              <span
                className="text-white"
                style={{ fontFamily: "notoSansReg", fontSize: "10px" }}
              >
                your transId {val.transId}
              </span>
            </div>
          </div>
        </Link>
      );
    });
  };

  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    // console.log('data pending local state', this.state.dataPending[32])
    return (
      <div className="big-container">
        <NavbarBack />
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="justify-content-md-center col-md-6">
              <h1 style={{ fontSize: "inherit" }}>PURCHASE HISTORY</h1>
              {this.renderHistory()}
            </div>
          </div>
        </div>
        <FooterMobile />
        <Footer />
      </div>
    );
  }
}

export default PurchaseHistory;
