import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Footer from '../../organism/Footer'
import FooterMobile from '../../organism/FooterMobile'
import NavbarBack from '../../organism/NavbarBack'

class Privacypolicy extends React.Component {
    render() {
        return (
            <div style={{ backgroundColor: 'black', minHeight: '100vh', padding: '20px' }}>
                <NavbarBack />
                <Container>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <h1 style={{ fontSize:'16px',}} className='py-3'>
                                PRIVACY POLICY
                            </h1>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                This Privacy Policy explains how we collect, store, use, disclose and treat your data when using our mobile applications '{"Applications"}'. This Privacy Policy explains the following, including:
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                Information regarding the use of cookies and similar technologies is describe in our Cookie Policy, which is an integral part of this Privacy Plilicy. when we refer to the Privacy Policy, we are reffering to the Privacy Policy and Cookies Policy.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                A. Data Controller <br/>
                                when this policy says "Sports TV", "we", "we" or "our", it refers to the entity Sport TV Portugal SA which is responsible for your data in accordance with this Privacy Policy("Responsible for Care").
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                B. Data We Collect <br/>
                                All personal data that we collect is related to the provision of the Application and its resources and is divided into three general categories:<br/>
                                1. Data that you provide to us. <br/>
                                2. Data is collected automatically. <br/>
                                3. Data we collect through third parties.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                C. Sharing Your Data<br/>
                                This section describes how data collected or generated through your use of the Application can be shared by you or us.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                With Your Consent - We will disclose your data if you consent to it. This will be explained when we collect your data, including the purposes for which that data is used.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                Service Providers - we may have to use subcontracted service proviiders, some of which may be located outside of the European Economic Area("EEA"), to provide  services to us, or to our users on our behalf. These service providers have access to your data solely to perform such tasks on our behalf and are contractually obliged to implement appropriate technical and organizational security measures against unauthorized disclosure of personal data and only process personal data in accordance with our instructions. And to the extent necessary to provide us with your services. You need to share your data.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                Aggregated Data - We may also share aggregated data (data about our users which we process in an unidentifiable way or do not refer to individual users) and other pseudonym data for regulatory compliance, market and sector analysis, demographic profile, marketing and advertising and other business purposes.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                Compliance with Laws - We may disclose your data to courts, law enforcement agencies and government aothorities (i) to comply with our legal obligations, (ii) to comply with legal processes and respond to claims imposed on Sport TV, (iii) respond to requests in connection with a criminal investigation or suspected or suspected illegal activity or other activity that could place us, or our users under legal responsibility, (iv) implement and administer our Terms and Conditions and other agreements made with users, or (v) protect the rights, property or personal safety of Sport Tv, its employees and members of the public.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                Protection of Our Rights - We may disclose your data if we deem it necessary to protect or defend the rights and legitimate interest of us, or our users, employees, directors, members or partners, and / or to ensure the safety of the Sport TV Application and / or community.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md={8}>
                            <p style={{ fontFamily: 'notoSansReg', fontSize: '12px' }} className='text-muted'>
                                D. Information Security<br/>
                                Sport TV maintains adequate technical, administrative and physical safeguards designed to prefent unauthorized access, use or disclosure of the personal data we collect or store and to ensure a level of security appropriate to the possible risks and varyiing degrees of severity for the rights and freedoms of individuals, who may be involved in our processing operations. We regularly monitor our systems for possible vulnerabilities and attcks. However, the security of data transmitted over the Internet cannot be guaranteed. You use the Application and provide us with data at your own disrection and risk.
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
                <Footer />
                <FooterMobile />
            </div>
        )
    }
}

export default Privacypolicy