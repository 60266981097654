import React from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarBack from "../../organism/NavbarBack";
import { API_URL } from "../../../config/common/baseurl";
import Cookise from "cookie-universal";
import { FAVORITE, ICSHAREXL } from "../../../assets/icon";
import { Modal } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

const cookie = new Cookise();

const domain = "https://mpro.world/";

class Pictures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDetailArticle: [],
      dataContributor: [],
      dataPhotos: [],
      favourite: false,
      idArticle: "",
      showShare: false,
      statusLike: "",
    };
  }
  componentDidMount = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    // console.log('ini',this.props.match.params.id)
    const data = { role: "MPRO Contributor" };
    axios
      .post(`${API_URL}/photo/contributor-role`, data, token)
      .then(async (res) => {
        // console.log('data pictures', res.data.data)
        const b = res.data.data.find(
          (x) => x._id === this.props.match.params.id
        );
        this.setState({ idArticle: b._id });
        // console.log('b =>', b._id);
        // console.log('b =>', b);
        if (b.like !== 0) {
          this.setState({ favourite: true });
        }
        this.setState({ dataDetailArticle: b, dataContributor: b.contributor });
        // let dataDetailArticle = []
        // for (var i = 0; i < res.data.data.length; i++) {
        //     console.log('cek', res.data.data[i])
        //     dataDetailArticle.push()
        //     this.setState({
        //         dataDetailArticle: dataDetailArticle
        //     })
        // }
        let dataPhotos = [];
        for (var i = 0; i < b.photo.length; i++) {
          // console.log('cek', b.photo[i])
          dataPhotos.push(b.photo[i]);
          this.setState({
            dataPhotos: dataPhotos,
          });
        }
      });
    // .catch((err) => console.log(err))
  };

  handleFavourite = () => {
    // console.log('id ', this.state.idArticle)
    // this.setState({ favourite: true });
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    // console.log('cek token', token)
    axios
      .put(`${API_URL}/photo/favorite/${this.state.idArticle}`, null, token)
      .then((res) => {
        // console.log('cekfav', res)
        if (this.state.statusLike) {
          this.setState({ statusLike: false });
        } else {
          this.setState({ statusLike: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleShare = () => {
    this.setState({ showShare: true });
  };

  handleShareDetail = () => {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    axios
      .put(`${API_URL}/article/share/${this.state.idArticle}`, null, token)
      .then((res) => {
        // console.log('share',res)
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  renderImagePhoto = () => {
    return this.state.dataPhotos.map((val, i) => {
      // console.log('ioioioi', val)
      return (
        <div className="container-fluid">
          <div className="d-flex justify-content-center overflow-auto">
            <div className="col-md-4">
              <div className="card text-center bg-dark">
                <img
                  src={val}
                  className="card-img img-fluid"
                  alt="onepict"
                  key={i}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    // console.log(this.state.idArticle)
    const {
      dataDetailArticle,
      dataContributor,
      favourite,
      showShare,
      statusLike,
    } = this.state;
    return (
      <div>
        <NavbarBack />
        <div className="container-fluid">
          <div className="row align-items-center flex-column">
            <div className="col-md-4 d-flex">
              <div className=" pt-2">
                <h5 className="title-livedetail">
                  {dataDetailArticle.description}
                </h5>
              </div>
            </div>
            <div className="col-md-4">
              <p className="date-livedetail text-muted">
                {dataDetailArticle.dateString}
              </p>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-between">
                <div className="d-flex contributor-text">
                  <img
                    src={dataContributor.photo}
                    className="rounded-circle img-fluid"
                    style={{ maxHeight: "50px", maxWidth: "50px" }}
                    alt="onepict"
                  />
                  <div className="d-flex flex-column m-2">
                    <span>{dataContributor.name}</span>
                    <span>{dataContributor.role}</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="px-2" onClick={this.handleShare}>
                    <img src={ICSHAREXL.default} alt="icon share" />
                    <p className="ictitle-livedetail text-muted">Share</p>
                  </div>
                  <div onClick={this.handleFavourite}>
                    {statusLike ? (
                      <i className="fas fa-heart pl-1"></i>
                    ) : (
                      <img
                        src={FAVORITE.default}
                        className="icfav-livedetail"
                        alt="icon share"
                      />
                    )}
                    <p className="ictitle-livedetail text-muted">Favorite</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderImagePhoto()}
        <Footer />
        <FooterMobile />
        <Modal
          show={showShare}
          onHide={() => this.setState({ popUpShare: true })}
        >
          <Modal.Header
            style={{ backgroundColor: "#040124" }}
            onClick={() => this.setState({ showShare: false })}
            closeButton
          >
            <h5>SHARE</h5>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#040124", minHeight: "50vh" }}>
            <div className="d-flex flex-column justify-content-around">
              <FacebookShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <FacebookIcon className="rounded"></FacebookIcon>
                <h5 className="ml-2 my-auto"> facebook </h5>
              </FacebookShareButton>
              <WhatsappShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <WhatsappIcon className="rounded"></WhatsappIcon>
                <h5 className="ml-2 my-auto"> WhatsaApp </h5>
              </WhatsappShareButton>
              <TelegramShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <TelegramIcon className="rounded"></TelegramIcon>
                <h5 className="ml-2 my-auto"> Telegram </h5>
              </TelegramShareButton>
              <TwitterShareButton
                className="d-flex mb-2"
                url={domain}
                quote="Mrpo, Indonesia best sports app"
                hashtag="#Mpro"
                onClick={this.handleShareDetail}
              >
                <TwitterIcon className="rounded"></TwitterIcon>
                <h5 className="ml-2 my-auto"> Twitter </h5>
              </TwitterShareButton>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Pictures;
