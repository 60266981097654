import React from "react";
import axios from "axios";
import { API_URL } from "../../../config/common/baseurl";
import { Link, Redirect } from "react-router-dom";
import { FOUR } from "../../../assets/image/update";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";
import NavbarComp2 from "../../organism/NavbarComp2";
import Trending from "../../organism/Trending";
import "../App/App.css";
import Cookise from "cookie-universal";

const cookie = new Cookise();

class VideoOneonone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoHighlight: [],
    };
  }

  componentDidMount() {
    const token = {
      headers: { access_token: cookie.get("access_token") },
    };
    const data = { category: "one on one" };
    axios.post(`${API_URL}/video/category`, data, token).then(async (res) => {
      // console.log('data video one on one', res.data)
      let videoHighlight = [];
      for (var i = 0; i < res.data.data.length; i++) {
        if (res.data.data) videoHighlight.push(res.data.data[i]);
        this.setState({
          videoHighlight: videoHighlight,
        });
      }
    });
    // .catch((err) => console.log(err))
  }

  handleTrending = () => {
    return <Trending />;
  };

  handleGetID = (e) => {
    const id = e.target.getAttribute("data-id");

    this.handlePlayVideo(id);
  };

  handlePlayVideo = (id) => {
    axios
      .get(`${API_URL}/video/play/${id}`, {
        headers: {
          access_token: cookie.get("access_token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        cookie.set("titlevideo", res.data.data.title, { path: "/" });
        cookie.set("linkvideo", res.data.link, { path: "/" });
        document.location.href = "/videoplay/";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleVideoHighlight = () => {
    return this.state.videoHighlight.map((val, i) => {
      return (
        <div
          onClick={(e) => this.handleGetID(e)}
          data-id={this.state.videoHighlight[i]._id}
          className="row justify-content-center align-items-center"
        >
          <div className="card-article col-md-4">
            <div className="card text-center bg-dark ">
              <img
                data-id={this.state.videoHighlight[i]._id}
                allowFullScreen
                src={this.state.videoHighlight[i].thumbnail}
                width="320"
                height="240"
                className="card-img img-fluid"
                controls
              ></img>
              <div className="card-body">
                <h5 className="card-title text-left">
                  {this.state.videoHighlight[i].title}
                </h5>
                <p className="card-text text-left text-muted">
                  {this.state.videoHighlight[i].dateString}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    if (
      cookie.get("access_token") == null ||
      cookie.get("access_token").length < 200 ||
      cookie.get("access_token").length > 250
    ) {
      return <Redirect to="/login" />;
    }
    const { location } = this.props;
    const { pathname } = location;
    // console.log('data d state', this.state.videoHighlight)
    return (
      <div>
        <NavbarComp2 />
        <div className="container-fluid ">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6">
              <ul className="title-video d-flex justify-content-around list-unstyled pt-3">
                <li>
                  <Link to="/video">
                    <span
                      style={{
                        color: `${
                          pathname === "/video" ? "#FAA21B" : "#FFFFFF"
                        }`,
                      }}
                    >
                      Highlight
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/videooneonone">
                    <span
                      style={{
                        color: `${
                          pathname === "/videooneonone" ? "#FAA21B" : "#FFFFFF"
                        }`,
                      }}
                    >
                      One on One
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/videocommunity">
                    <span
                      style={{
                        color: `${
                          pathname === "/videocommunity" ? "#FAA21B" : "#FFFFFF"
                        }`,
                      }}
                    >
                      Community
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid">{this.handleVideoHighlight()}</div>
        <Footer />
        <FooterMobile />
      </div>
    );
  }
}

export default VideoOneonone;
