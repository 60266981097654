export const HOMEMOBXL = require('./home-xl.png')
export const EVENTMOBXL = require('./event-xl.png')
export const LIBRARYMOBXL = require('./library-xl.png')
export const LIVEMOBXL = require('./live-xl.png')
export const VIDEOMOBXL = require('./video-xl.png')
export const ARTICLEMOBXL = require('./ic_article.png')
export const ICPREMIUM = require('./ic_premium.png')
export const ICHOMEON = require('./home_active.png')
export const ICEVENTON = require('./event_active.png')
export const ICLIBON = require('./library_active.png')
export const ICVIDEOON = require('./video_active.png')
export const ICLIVEON = require('./live_active.png')
export const ICARTICLEON = require('./ic_article_on.png')
// export const IGSM = require('./ig.png')
// export const FBXL = require('./fb-xl.png')
// export const FBSM = require('./fb.png')
// export const TTXL = require('./twitter-xl.png')
// export const TTSM = require('./twitter.png')
// export const UTUBEXL = require('./youtube-xl.png')
// export const UTUBESM = require('./youtube.png')