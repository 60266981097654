import React from "react";
import "../../pages/App/App.css";
import { LOGOIMGXL } from "../../../assets/logo";
import { TTXL, IGXL, FBXL, UTUBEXL } from "../../../assets/icon";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-bigcontainer pt-5">
        <div className="footer-container">
          <div>
            <img src={LOGOIMGXL.default} alt="logo-mpro-xl" />
          </div>
          <ul className="navbar-footer">
            <Link to="/home" style={{ textDecoration: "none" }}>
              <li className="nav-footer">Home</li>
            </Link>
            <Link to="/article" style={{ textDecoration: "none" }}>
              <li className="nav-footer">Article</li>
            </Link>
            <Link to="/live" style={{ textDecoration: "none" }}>
              <li className="nav-footer">Live</li>
            </Link>
            <Link to="/library" style={{ textDecoration: "none" }}>
              <li className="nav-footer">Library</li>
            </Link>
            <Link to="/video" style={{ textDecoration: "none" }}>
              <li className="nav-footer">Video</li>
            </Link>
          </ul>
          <ul>
            <Link to="./aboutus" style={{ textDecoration: "none" }}>
              <li className="nav-footer">About Us</li>
            </Link>
            <Link to="./contactus" style={{ textDecoration: "none" }}>
              <li className="nav-footer">Contact Us</li>
            </Link>
            <Link to="./privacypolicy" style={{ textDecoration: "none" }}>
              <li className="nav-footer">Privacy Policy</li>
            </Link>
            <Link to="./termcondition" style={{ textDecoration: "none" }}>
              <li className="nav-footer">Term & Conditions</li>
            </Link>
            <Link to="./returnpolicy" style={{ textDecoration: "none" }}>
              <li className="nav-footer">Refund and Returns Policy</li>
            </Link>
          </ul>
          <div>
            <div className="footer-logo">
              <img src={IGXL.default} alt="instagram" />
              <img src={FBXL.default} alt="facebook" />
              <img src={TTXL.default} alt="twitter" />
              <img src={UTUBEXL.default} alt="youtube" />
            </div>
          </div>
        </div>
        <div class="footer-copyright">
          Copyright <span className="font-weight-bold">2021 MPRO </span>All
          Right Reserved
        </div>
      </div>
    );
  }
}

export default Footer;
