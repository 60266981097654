import React from 'react'
import { LOGOIMGXL } from '../../../assets/logo/index'
import { Link } from 'react-router-dom'
import '../../pages/App/App.css'
import {  USERXL } from '../../../assets/icon' 

class NavbarComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: true
        }
        this.handleclick = this.handleClick.bind(this)
    }
    
    handleClick = () => {
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn
        })

        )
    }
    handleLoopIcon = (e) => {
        e.preventdefault()
        // console.log('you clicked me')
    }
    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg bg-dark">
                    <div className='col'>
                        <div className='row'>
                            <div className="d-flex justify-content-between ms-3">
                                <Link to='/'>
                                    <img src={LOGOIMGXL.default}
                                        alt="logompro" />
                                </Link>
                                <div className="collapse navbar-collapse ms-5 " id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                                        <Link style={{ textDecoration: 'none' }} to='/home' >
                                            <li className="nav-item mx-3">
                                                <div onClick={this.handleChangetext} className="nav-link text-white" aria-current="page">Home</div>
                                            </li>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} to='/article' >
                                            <li className="nav-item mx-3">
                                                <div className="nav-link text-white" aria-current="page">Article</div>
                                            </li>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} to='/live'>
                                            <li className="nav-item mx-3">
                                                <div className="nav-link text-white" aria-current="page" >Calendar</div>
                                            </li>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} to='/library'>
                                            <li className="nav-item mx-3">
                                                <div className="nav-link text-white" aria-current="page">Library</div>
                                            </li>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} to='/video'>
                                            <li className="nav-item mx-3">
                                                <div className="nav-link text-white" href="#">Video</div>
                                            </li>
                                        </Link>
                                    </ul>
                                </div>
                                <div className='col-xs-1 text-white mt-1 d-flex'>
                                    {/* <div className='pr-2' >
                                        {this.state.isToggleOn ? 
                                        <img onClick={this.handleclick} src={LOOPXL.default}
                                            alt="ikon-search" /> 
                                            : 
                                            
                                            <div class="input-group rounded">
                                            <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search"
                                                aria-describedby="search-addon" />
                                            <span class="input-group-text border-0" id="search-addon">
                                                <i onClick={this.handleclick}  class="fas fa-search"></i>
                                            </span>
                                        </div>}

                                    </div> */}
                                    <Link className='pl-3' style={{ textDecoration: 'none' }} to='/login'>                    
                                            <img src={USERXL.default}
                                            alt="ikon-username" /> 
                                    </Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}



export default NavbarComp