import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavbarBack from "../../organism/NavbarBack";
import Footer from "../../organism/Footer";
import FooterMobile from "../../organism/FooterMobile";

class Contactus extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "black",
          minHeight: "100vh",
          padding: "20px",
        }}
      >
        <NavbarBack />
        <Container>
          <Row>
            <Col></Col>
            <Col md={8}>
              <h1 style={{ fontSize: "16px" }} className="py-3">
                CONTACT
              </h1>
            </Col>
            <Col></Col>
          </Row>
        </Container>
        <Container style={{ minHeight: "70vh" }}>
          <Row>
            <Col md={4}>
              <div style={{ display: "flex" }}>
                <i className="far fa-envelope pt-2 pr-2 text-white"></i>
                <p>info@mpr.com</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div style={{ display: "flex" }}>
                <i className="fas fa-phone-alt pt-2 pr-2 text-white"></i>
                <p>+62 2134 1234</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div style={{ display: "flex" }}>
                <i className="fas fa-phone-alt pt-2 pr-2 text-white"></i>
                <p>+62 2134 1234</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div style={{ display: "flex" }}>
                <i className="fas fa-map-marker-alt pt-2 pr-2 text-white"></i>
                <p>4326 Hilltop, Streeet, Springfield, MA, 01109</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
        <FooterMobile />
      </div>
    );
  }
}

export default Contactus;
